import * as React from 'react';
// import PropTypes from 'prop-types';

import styled from 'styled-components';
import { media } from 'styles/theme';

const Svg = styled.svg`
  height: 100%;
  max-height: 54px;
  ${media.ns} {
    max-height: 87px;
    max-width: initial;
  }
`;

function Classroom(props) {
  return (
    <Svg
      viewBox="0 0 172 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#classroom)"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      >
        <path d="M37.947 32.025c0 .953.762 1.715 1.716 1.715H49.96c.953 0 1.716-.762 1.716-1.715 0-.954-.763-1.716-1.716-1.716H39.663c-.953 0-1.716.762-1.716 1.716zM3.623 32.025c0 .953.763 1.715 1.716 1.715h10.297c.954 0 1.716-.762 1.716-1.715 0-.954-.762-1.716-1.716-1.716H5.34c-.953 0-1.716.762-1.716 1.716zM20.785 32.025c0 .953.763 1.715 1.716 1.715h10.297c.954 0 1.716-.762 1.716-1.715 0-.954-.762-1.716-1.716-1.716H22.501c-.953 0-1.716.762-1.716 1.716zM56.825 33.74h10.297c.953 0 1.716-.762 1.716-1.715 0-.954-.763-1.716-1.716-1.716H56.825c-.954 0-1.716.762-1.716 1.716 0 .953.762 1.715 1.716 1.715z" />
        <path d="M.19 20.778v6.862a3.418 3.418 0 003.433 3.431H68.84a3.418 3.418 0 003.431-3.43v-6.863a3.418 3.418 0 00-3.432-3.431H3.623a3.418 3.418 0 00-3.432 3.43zM37.947 58.56c0 .952.762 1.715 1.716 1.715H49.96c.953 0 1.716-.763 1.716-1.716s-.763-1.715-1.716-1.715H39.663c-.953 0-1.716.762-1.716 1.715zM3.623 58.56c0 .952.763 1.715 1.716 1.715h10.297c.954 0 1.716-.763 1.716-1.716s-.762-1.715-1.716-1.715H5.34c-.953 0-1.716.762-1.716 1.715zM20.785 58.56c0 .952.763 1.715 1.716 1.715h10.297c.954 0 1.716-.763 1.716-1.716s-.762-1.715-1.716-1.715H22.501c-.953 0-1.716.762-1.716 1.715zM56.825 60.275h10.297c.953 0 1.716-.763 1.716-1.716s-.763-1.715-1.716-1.715H56.825c-.954 0-1.716.762-1.716 1.715 0 .953.762 1.716 1.716 1.716z" />
        <path d="M.19 47.313v6.862a3.418 3.418 0 003.433 3.431H68.84a3.418 3.418 0 003.431-3.431v-6.862a3.417 3.417 0 00-3.432-3.432H3.623a3.417 3.417 0 00-3.432 3.431zM37.947 85.094c0 .953.762 1.715 1.716 1.715H49.96c.953 0 1.716-.762 1.716-1.715 0-.953-.763-1.716-1.716-1.716H39.663c-.953 0-1.716.763-1.716 1.716zM3.623 85.094c0 .953.763 1.715 1.716 1.715h10.297c.954 0 1.716-.762 1.716-1.715 0-.953-.762-1.716-1.716-1.716H5.34c-.953 0-1.716.763-1.716 1.716zM20.785 85.094c0 .953.763 1.715 1.716 1.715h10.297c.954 0 1.716-.762 1.716-1.715 0-.953-.762-1.716-1.716-1.716H22.501c-.953 0-1.716.763-1.716 1.716zM56.825 86.81h10.297c.953 0 1.716-.763 1.716-1.716s-.763-1.716-1.716-1.716H56.825c-.954 0-1.716.763-1.716 1.716s.762 1.715 1.716 1.715z" />
        <path d="M.19 73.847v6.863a3.418 3.418 0 003.433 3.43H68.84a3.418 3.418 0 003.431-3.43v-6.863a3.417 3.417 0 00-3.432-3.431H3.623a3.417 3.417 0 00-3.432 3.431zM137.486 32.025c0 .953.762 1.715 1.716 1.715h10.297c.953 0 1.716-.762 1.716-1.715 0-.954-.763-1.716-1.716-1.716h-10.297c-.954 0-1.716.762-1.716 1.716zM103.162 32.025c0 .953.763 1.715 1.716 1.715h10.297c.954 0 1.716-.762 1.716-1.715 0-.954-.762-1.716-1.716-1.716h-10.297c-.953 0-1.716.762-1.716 1.716zM120.324 32.025c0 .953.762 1.715 1.716 1.715h10.297c.953 0 1.716-.762 1.716-1.715 0-.954-.763-1.716-1.716-1.716H122.04c-.954 0-1.716.762-1.716 1.716zM156.364 33.74h10.297c.953 0 1.716-.762 1.716-1.715 0-.954-.763-1.716-1.716-1.716h-10.297a1.71 1.71 0 00-1.717 1.716 1.71 1.71 0 001.717 1.715z" />
        <path d="M99.73 20.778v6.862a3.418 3.418 0 003.432 3.431h65.215a3.417 3.417 0 003.432-3.43v-6.863a3.417 3.417 0 00-3.432-3.431h-65.215a3.418 3.418 0 00-3.433 3.43zM137.486 58.56c0 .952.762 1.715 1.716 1.715h10.297c.953 0 1.716-.763 1.716-1.716s-.763-1.715-1.716-1.715h-10.297c-.954 0-1.716.762-1.716 1.715zM103.162 58.56c0 .952.763 1.715 1.716 1.715h10.297c.954 0 1.716-.763 1.716-1.716s-.762-1.715-1.716-1.715h-10.297c-.953 0-1.716.762-1.716 1.715zM120.324 58.56c0 .952.762 1.715 1.716 1.715h10.297c.953 0 1.716-.763 1.716-1.716s-.763-1.715-1.716-1.715H122.04c-.954 0-1.716.762-1.716 1.715zM156.364 60.275h10.297c.953 0 1.716-.763 1.716-1.716s-.763-1.715-1.716-1.715h-10.297a1.71 1.71 0 00-1.717 1.715 1.71 1.71 0 001.717 1.716z" />
        <path d="M99.73 47.313v6.862a3.418 3.418 0 003.432 3.431h65.215a3.417 3.417 0 003.432-3.431v-6.862a3.417 3.417 0 00-3.432-3.432h-65.215a3.417 3.417 0 00-3.433 3.431zM137.486 85.094c0 .953.762 1.715 1.716 1.715h10.297c.953 0 1.716-.762 1.716-1.715 0-.953-.763-1.716-1.716-1.716h-10.297c-.954 0-1.716.763-1.716 1.716zM103.162 85.094c0 .953.763 1.715 1.716 1.715h10.297c.954 0 1.716-.762 1.716-1.715 0-.953-.762-1.716-1.716-1.716h-10.297c-.953 0-1.716.763-1.716 1.716zM120.324 85.094c0 .953.762 1.715 1.716 1.715h10.297c.953 0 1.716-.762 1.716-1.715 0-.953-.763-1.716-1.716-1.716H122.04c-.954 0-1.716.763-1.716 1.716zM156.364 86.81h10.297c.953 0 1.716-.763 1.716-1.716s-.763-1.716-1.716-1.716h-10.297a1.71 1.71 0 00-1.717 1.716 1.71 1.71 0 001.717 1.715z" />
        <path d="M99.73 73.847v6.863a3.418 3.418 0 003.432 3.43h65.215a3.417 3.417 0 003.432-3.43v-6.863a3.417 3.417 0 00-3.432-3.431h-65.215a3.417 3.417 0 00-3.433 3.431zM96.297 13.915V.191H75.703v13.724h20.594z" />
      </g>
      <defs>
        <clipPath id="classroom">
          <path fill="#fff" d="M0 0h172v87H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

Classroom.propTypes = {};

Classroom.defaultProps = {};

export default Classroom;
