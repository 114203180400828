import * as React from 'react';
import { CardTitle } from 'components/Card';
import SeatingCard from './SeatingCard';

// icons
import Breakout from './icons/Breakout';
import Circular from './icons/Circular';
import Classroom from './icons/Classroom';
import Council from './icons/Council';
import Flexible from './icons/Flexible';
import Hollow from './icons/Hollow';
import Rectangular from './icons/Rectangular';
import Theater from './icons/Theater';

function useSeatingOptions(t) {
  const seatingOptions = [
    {
      value: 'rectangular',
      content: (
        <SeatingCard icon={<Rectangular />} title={t('seating:rectangular')} />
      ),
    },
    {
      value: 'circular',
      content: (
        <SeatingCard icon={<Circular />} title={t('seating:circular')} />
      ),
    },
    {
      value: 'hollow',
      content: (
        <SeatingCard
          icon={<Hollow style={{ maxHeight: 84, height: '100%' }} />}
          title={t('seating:hollow')}
        />
      ),
    },
    {
      value: 'theater',
      content: (
        <SeatingCard
          icon={<Theater style={{ maxHeight: 99, height: '100%' }} />}
          title={t('seating:theater')}
        />
      ),
    },
    {
      value: 'classroom',
      content: (
        <SeatingCard icon={<Classroom />} title={t('seating:classroom')} />
      ),
    },
    {
      value: 'council',
      content: (
        <SeatingCard
          icon={<Council style={{ maxHeight: 105, height: '100%' }} />}
          title={t('seating:council')}
        />
      ),
    },
    {
      value: 'breakout',
      content: (
        <SeatingCard icon={<Breakout />} title={t('seating:breakout')} />
      ),
    },
    {
      value: 'flexible',
      content: (
        <SeatingCard
          icon={<Flexible style={{ maxHeight: 96, height: '100%' }} />}
          title={t('seating:flexible')}
        />
      ),
    },
    {
      value: 'other',
      content: <CardTitle>{t('common:other')}</CardTitle>,
    },
  ];
  return seatingOptions;
}

export default useSeatingOptions;
