import * as React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { media } from 'styles/theme';

const Svg = styled.svg`
  height: 100%;
  max-width: 108px;
  ${media.ns} {
    max-height: 69px;
    max-width: initial;
  }
`;

function Rectangular(props) {
  return (
    <Svg
      viewBox="0 0 186 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#rectangular)"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      >
        <path d="M169.535 32.189a36.594 36.594 0 010-14.64c5.146-1.284 10.291-1.54 15.436-.77a41.103 41.103 0 010 16.18c-5.145.77-10.29.514-15.436-.77zM169.535 51.45a36.594 36.594 0 010-14.639c5.146-1.284 10.291-1.54 15.436-.77a41.103 41.103 0 010 16.18c-5.145.77-10.29.514-15.436-.77z" />
        <path d="M183.685 51.45c-.129-4.88-.129-9.759 0-14.639.643-1.284 1.329-1.54 1.972-.77.129 5.393.129 10.787 0 16.18-.643.77-1.329.514-1.972-.77zM183.685 32.189c-.129-4.88-.129-9.76 0-14.64.643-1.284 1.329-1.54 1.972-.77.129 5.393.129 10.787 0 16.18-.643.77-1.329.514-1.972-.77zM16.465 36.811c.986 4.88.986 9.76 0 14.64-5.146 1.283-10.29 1.54-15.436.77a41.106 41.106 0 010-16.18c5.145-.77 10.29-.514 15.436.77zM16.466 17.55c.986 4.88.986 9.759 0 14.639-5.146 1.284-10.29 1.54-15.436.77a41.106 41.106 0 010-16.18c5.145-.77 10.29-.514 15.436.77z" />
        <path d="M2.273 17.55c.128 4.88.128 9.759 0 14.639-.644 1.284-1.33 1.54-1.973.77a339.25 339.25 0 010-16.18c.686-.77 1.33-.514 1.973.77zM2.273 36.811c.128 4.88.128 9.76 0 14.64-.644 1.283-1.33 1.54-1.973.77a339.25 339.25 0 010-16.18c.686-.77 1.33-.514 1.973.77zM33.058 16.438a36.775 36.775 0 01-14.664 0c-1.286-5.137-1.543-10.273-.772-15.41a41.313 41.313 0 0116.208 0c.772 5.137.514 10.273-.772 15.41zM52.353 16.437a36.772 36.772 0 01-14.664 0c-1.287-5.137-1.544-10.273-.772-15.41a41.314 41.314 0 0116.207 0c.772 5.137.515 10.273-.771 15.41zM71.647 16.437a36.772 36.772 0 01-14.664 0c-1.286-5.137-1.543-10.273-.771-15.41a41.314 41.314 0 0116.207 0c.772 5.137.515 10.273-.772 15.41zM90.942 16.437a36.772 36.772 0 01-14.664 0c-1.286-5.137-1.544-10.273-.772-15.41a41.313 41.313 0 0116.208 0c.771 5.137.514 10.273-.772 15.41z" />
        <path d="M90.942 2.269c-4.888.128-9.776.128-14.664 0-1.286-.642-1.544-1.327-.772-1.97a340.982 340.982 0 0116.208 0c.771.685.514 1.328-.772 1.97zM71.647 2.269c-4.888.128-9.776.128-14.664 0-1.286-.642-1.543-1.327-.771-1.97a340.986 340.986 0 0116.207 0c.772.685.515 1.328-.772 1.97zM52.353 2.269c-4.888.128-9.776.128-14.664 0-1.287-.642-1.544-1.327-.772-1.97a340.986 340.986 0 0116.207 0c.772.685.515 1.328-.771 1.97zM33.058 2.269c-4.888.128-9.776.128-14.664 0-1.286-.642-1.543-1.327-.772-1.97A340.982 340.982 0 0133.83.3c.772.685.514 1.328-.772 1.97zM110.151 16.437a36.773 36.773 0 01-14.664 0C94.2 11.3 93.943 6.164 94.715 1.027a41.316 41.316 0 0116.208 0c.771 5.137.514 10.273-.772 15.41zM129.445 16.437a36.772 36.772 0 01-14.664 0c-1.286-5.137-1.543-10.273-.771-15.41a41.311 41.311 0 0116.207 0c.772 5.137.515 10.273-.772 15.41zM148.74 16.437a36.772 36.772 0 01-14.664 0c-1.286-5.137-1.544-10.273-.772-15.41a41.316 41.316 0 0116.208 0c.772 5.137.514 10.273-.772 15.41zM168.034 16.437a36.767 36.767 0 01-14.663 0c-1.287-5.137-1.544-10.273-.772-15.41a41.311 41.311 0 0116.207 0c.772 5.137.515 10.273-.772 15.41z" />
        <path d="M168.034 2.269c-4.887.128-9.775.128-14.663 0-1.287-.642-1.544-1.327-.772-1.97a340.965 340.965 0 0116.207 0c.772.685.515 1.328-.772 1.97zM148.74 2.269c-4.888.128-9.776.128-14.664 0-1.286-.642-1.544-1.327-.772-1.97a341.007 341.007 0 0116.208 0c.772.685.514 1.328-.772 1.97zM129.445 2.269c-4.888.128-9.776.128-14.664 0-1.286-.642-1.543-1.327-.771-1.97a340.965 340.965 0 0116.207 0c.772.685.515 1.328-.772 1.97zM110.151 2.269c-4.888.128-9.776.128-14.664 0-1.286-.642-1.544-1.327-.772-1.97a341.007 341.007 0 0116.208 0c.771.685.514 1.328-.772 1.97zM153.371 52.606a36.767 36.767 0 0114.663 0c1.287 5.137 1.544 10.273.772 15.41a41.311 41.311 0 01-16.207 0c-.772-5.137-.515-10.273.772-15.41zM134.076 52.606a36.772 36.772 0 0114.664 0c1.286 5.137 1.544 10.273.772 15.41a41.316 41.316 0 01-16.208 0c-.772-5.137-.514-10.273.772-15.41zM114.781 52.606a36.772 36.772 0 0114.664 0c1.287 5.137 1.544 10.273.772 15.41a41.311 41.311 0 01-16.207 0c-.772-5.137-.515-10.273.771-15.41zM95.487 52.606a36.773 36.773 0 0114.664 0c1.286 5.137 1.543 10.273.772 15.41a41.316 41.316 0 01-16.208 0c-.772-5.137-.514-10.273.772-15.41z" />
        <path d="M95.487 66.731c4.888-.128 9.776-.128 14.664 0 1.286.643 1.543 1.327.772 1.97-5.403.128-10.805.128-16.208 0-.772-.643-.514-1.327.772-1.97zM114.781 66.731c4.888-.128 9.776-.128 14.664 0 1.287.643 1.544 1.327.772 1.97-5.402.128-10.805.128-16.207 0-.772-.643-.515-1.327.771-1.97zM134.076 66.731c4.888-.128 9.776-.128 14.664 0 1.286.643 1.544 1.327.772 1.97-5.403.128-10.805.128-16.208 0-.772-.643-.514-1.327.772-1.97zM153.371 66.731c4.888-.128 9.776-.128 14.663 0 1.287.643 1.544 1.327.772 1.97-5.402.128-10.805.128-16.207 0-.772-.643-.515-1.327.772-1.97zM76.278 52.606a36.772 36.772 0 0114.664 0c1.286 5.137 1.543 10.273.772 15.41a41.313 41.313 0 01-16.208 0c-.772-5.137-.514-10.273.772-15.41zM56.983 52.606a36.772 36.772 0 0114.664 0c1.287 5.137 1.544 10.273.772 15.41a41.314 41.314 0 01-16.207 0c-.772-5.137-.515-10.273.771-15.41zM37.689 52.606a36.772 36.772 0 0114.664 0c1.286 5.137 1.543 10.273.771 15.41a41.314 41.314 0 01-16.207 0c-.772-5.137-.515-10.273.772-15.41zM18.394 52.606a36.772 36.772 0 0114.664 0c1.286 5.137 1.544 10.273.772 15.41a41.313 41.313 0 01-16.208 0c-.771-5.137-.514-10.273.772-15.41z" />
        <path d="M18.394 66.731c4.888-.128 9.776-.128 14.664 0 1.286.643 1.544 1.327.772 1.97-5.403.128-10.805.128-16.208 0-.771-.643-.514-1.327.772-1.97zM37.689 66.731c4.888-.128 9.776-.128 14.664 0 1.286.643 1.543 1.327.771 1.97-5.402.128-10.805.128-16.207 0-.772-.643-.515-1.327.772-1.97zM56.983 66.731c4.888-.128 9.776-.128 14.664 0 1.287.643 1.544 1.327.772 1.97-5.402.128-10.805.128-16.207 0-.772-.643-.515-1.327.771-1.97zM76.278 66.731c4.888-.128 9.776-.128 14.664 0 1.286.643 1.543 1.327.772 1.97-5.403.128-10.805.128-16.208 0-.772-.643-.514-1.327.772-1.97zM171.036 56.159H15.393a1.262 1.262 0 01-1.287-1.284V15.067c0-.728.558-1.284 1.287-1.284h155.643c.729 0 1.286.556 1.286 1.284v39.808c0 .727-.557 1.284-1.286 1.284z" />
      </g>
      <defs>
        <clipPath id="rectangular">
          <path fill="#fff" d="M0 0h186v69H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

Rectangular.propTypes = {};

Rectangular.defaultProps = {};

export default Rectangular;
