import * as React from 'react';
// import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CardArea from 'components/CardArea';
import Layout from 'components/Layout';
import Header from 'components/Header';
import Title from 'components/Title';

import { getSearch, gtmSeating } from 'utils/helpers';
import routes from 'utils/routes';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';
import useSeatingOptions from 'pageComponents/seating/useSeatingOptions';

function Seating(props) {
  const [selectedValue, setSelectedValue] = useQueryParam(
    'seating',
    StringParam
  );
  const { t } = useTranslation(['common', 'nav', 'seating']);
  const seatingOptions = useSeatingOptions(t);

  React.useEffect(() => {
    // On unmount - send gtm event
    return () => {
      gtmSeating();
    };
    // eslint-disable-next-line
  }, []);

  function handleSelect(val) {
    setSelectedValue(val);
  }

  const navProps = {
    backButton: {
      to: `${routes.roomSize}${getSearch()}`,
    },
    nextButton: {
      disabled: !selectedValue,
      to: `${routes.microphonePlacement}${getSearch()}`,
    },
  };

  return (
    <Layout navProps={navProps}>
      <Header pageNumber={3} t={t} />
      <Title
        subTitle={t('common:globalSubTitle')}
        title={t('seating:title')}
        tooltip={t('seating:titleTooltip')}
      />
      <CardArea
        columns={3}
        handleSelect={handleSelect}
        selectedValue={selectedValue}
        values={seatingOptions}
      />
    </Layout>
  );
}

Seating.propTypes = {};

Seating.defaultProps = {};

export default Seating;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "nav", "seating"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
