import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'components/Text';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

const Title = styled(Text)`
  margin-top: 6px;
  text-align: center;
  text-transform: upercase;
`;

function SeatingCard({ icon, title }) {
  return (
    <>
      <IconWrapper>{icon}</IconWrapper>
      <Title font="ostrichBold" size="smallP">
        {title}
      </Title>
    </>
  );
}

SeatingCard.propTypes = {};

SeatingCard.defaultProps = {};

export default SeatingCard;
