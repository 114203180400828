import * as React from 'react';

function Hollow(props) {
  return (
    <svg
      viewBox="0 0 95 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.56 6.78a29.402 29.402 0 015.815 10.177 31.365 31.365 0 01-10.303 6.74C4.216 20.36 2.074 16.613.68 12.418c3.196-2.578 6.834-4.47 10.88-5.638z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M1.836 12.454A200.584 200.584 0 017.65 22.63c.068 1.17-.306 1.616-1.054 1.341A244.963 244.963 0 01.17 12.73c.17-.825.714-.894 1.666-.275z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M11.56 18.058a29.402 29.402 0 015.815 10.176 31.365 31.365 0 01-10.303 6.739C4.216 31.638 2.074 27.891.68 23.696c3.196-2.578 6.834-4.47 10.88-5.638z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M1.836 23.73A200.584 200.584 0 017.65 33.907c.068 1.17-.306 1.616-1.054 1.341A246.021 246.021 0 01.17 23.971c.17-.79.714-.86 1.666-.24z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M11.56 29.3a29.402 29.402 0 015.815 10.177 31.365 31.365 0 01-10.303 6.739A32.87 32.87 0 01.68 34.972c3.196-2.613 6.834-4.504 10.88-5.673z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M1.836 35.007A200.584 200.584 0 017.65 45.184c.068 1.169-.306 1.616-1.054 1.34A264.635 264.635 0 01.17 35.283c.17-.825.714-.928 1.666-.275z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M11.56 40.577a29.402 29.402 0 015.815 10.177 31.365 31.365 0 01-10.303 6.738A33.04 33.04 0 01.646 46.25c3.23-2.613 6.868-4.504 10.914-5.673z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M1.836 46.25A200.672 200.672 0 017.65 56.427c.068 1.169-.306 1.615-1.054 1.34A264.635 264.635 0 01.17 46.525c.17-.79.714-.894 1.666-.275z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M11.56 51.854a29.402 29.402 0 015.815 10.177 31.365 31.365 0 01-10.303 6.738A33.04 33.04 0 01.646 57.527c3.23-2.613 6.868-4.504 10.914-5.673z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M1.836 57.527A200.584 200.584 0 017.65 67.704c.068 1.169-.306 1.615-1.054 1.34A264.76 264.76 0 01.17 57.802c.17-.825.714-.894 1.666-.275zM83.44 6.78a29.403 29.403 0 00-5.815 10.177 31.366 31.366 0 0010.303 6.74 33.041 33.041 0 006.426-11.243C91.124 9.84 87.486 7.95 83.439 6.78z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M93.164 12.454A200.591 200.591 0 0087.35 22.63c-.068 1.17.306 1.616 1.054 1.341A264.763 264.763 0 0094.83 12.73c-.136-.825-.714-.894-1.666-.275z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M83.44 18.058a29.402 29.402 0 00-5.815 10.176 31.366 31.366 0 0010.303 6.739 33.041 33.041 0 006.426-11.242c-3.23-2.613-6.868-4.504-10.915-5.673z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M93.164 23.73a200.591 200.591 0 00-5.814 10.177c-.068 1.17.306 1.616 1.054 1.341a264.763 264.763 0 006.426-11.242c-.136-.826-.714-.894-1.666-.276z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M83.44 29.3a29.402 29.402 0 00-5.815 10.177 31.366 31.366 0 0010.303 6.739 33.041 33.041 0 006.426-11.243c-3.23-2.613-6.868-4.504-10.915-5.673z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M93.164 35.007a200.591 200.591 0 00-5.814 10.177c-.068 1.169.306 1.616 1.054 1.34a264.639 264.639 0 006.426-11.242c-.136-.825-.714-.928-1.666-.275z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M83.44 40.577a29.402 29.402 0 00-5.815 10.177 31.366 31.366 0 0010.303 6.738 33.041 33.041 0 006.426-11.242c-3.23-2.613-6.868-4.504-10.915-5.673z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M93.164 46.25a200.678 200.678 0 00-5.814 10.177c-.068 1.169.306 1.615 1.054 1.34a264.639 264.639 0 006.426-11.242c-.136-.79-.714-.894-1.666-.275z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M83.44 51.854a29.402 29.402 0 00-5.815 10.177 31.366 31.366 0 0010.303 6.738 33.041 33.041 0 006.426-11.242c-3.23-2.613-6.868-4.504-10.915-5.673z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M93.164 57.527a200.591 200.591 0 00-5.814 10.177c-.068 1.169.306 1.615 1.054 1.34a264.763 264.763 0 006.426-11.242c-.136-.825-.714-.894-1.666-.275zM64.637 70.798c3.876-.791 7.752-.791 11.628 0 1.02 4.126 1.224 8.251.612 12.377a32.35 32.35 0 01-12.852 0c-.612-4.126-.408-8.251.612-12.377zM49.336 70.798c3.876-.791 7.752-.791 11.629 0 1.02 4.126 1.224 8.251.612 12.377a32.35 32.35 0 01-12.853 0c-.612-4.126-.408-8.251.612-12.377zM34.035 70.798c3.877-.791 7.753-.791 11.629 0 1.02 4.126 1.224 8.251.612 12.377a32.35 32.35 0 01-12.853 0c-.612-4.126-.408-8.251.612-12.377zM18.735 70.798c3.876-.791 7.752-.791 11.628 0 1.02 4.126 1.224 8.251.612 12.377a32.35 32.35 0 01-12.852 0c-.612-4.126-.408-8.251.612-12.377z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M18.735 82.143c3.876-.103 7.752-.103 11.628 0 1.02.516 1.224 1.066.612 1.582-4.284.103-8.568.103-12.852 0-.612-.516-.408-1.031.612-1.582zM34.035 82.143c3.877-.103 7.753-.103 11.629 0 1.02.516 1.224 1.066.612 1.582-4.284.103-8.568.103-12.853 0-.612-.516-.408-1.031.612-1.582zM49.336 82.143c3.876-.103 7.752-.103 11.629 0 1.02.516 1.224 1.066.612 1.582-4.285.103-8.569.103-12.853 0-.612-.516-.408-1.031.612-1.582zM64.637 82.143c3.876-.103 7.752-.103 11.628 0 1.02.516 1.224 1.066.612 1.582-4.284.103-8.568.103-12.852 0-.612-.516-.408-1.031.612-1.582zM67.39 2.14v56.728c0 .584-.441 1.031-1.02 1.031H28.63c-.579 0-1.02-.447-1.02-1.031V2.139c0-.584-.443-1.031-1.02-1.031H16.388c-.578 0-1.02.447-1.02 1.031v69.106c0 .584.442 1.031 1.02 1.031H78.61c.578 0 1.02-.447 1.02-1.031V2.139c0-.584-.442-1.031-1.02-1.031h-10.2c-.544 0-1.02.447-1.02 1.031z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

Hollow.propTypes = {};

Hollow.defaultProps = {};

export default Hollow;
