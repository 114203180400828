import * as React from 'react';
// import PropTypes from 'prop-types';

function Theater(props) {
  return (
    <svg
      viewBox="0 0 113 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#theater)"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      >
        <path d="M2.166.438c2.547-.38 5.094-.38 7.641 0a1.778 1.778 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0A1.709 1.709 0 01.35 9.839C.001 7.153.146 4.496.703 1.81c.146-.7.732-1.255 1.463-1.372z" />
        <path d="M.966 10.306c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM15.311.438a25.78 25.78 0 017.64 0 1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.486 26.486 0 01.44-8.029c.087-.7.672-1.255 1.375-1.372z" />
        <path d="M14.14 10.306c3.337-.088 6.674-.088 10.012 0 .878.438 1.053.905.526 1.343-3.688.087-7.377.087-11.065 0-.527-.467-.352-.905.527-1.343zM28.485.438c2.547-.38 5.093-.38 7.64 0A1.778 1.778 0 0137.59 1.81c.556 2.686.703 5.343.44 8.029-.089.788-.703 1.43-1.465 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.673-1.255 1.376-1.372z" />
        <path d="M27.313 10.306c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM41.658.438a25.78 25.78 0 017.64 0 1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029c.087-.7.673-1.255 1.375-1.372z" />
        <path d="M40.487 10.306c3.337-.088 6.674-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.378.087-11.066 0-.527-.467-.351-.905.527-1.343zM2.166 14.948c2.547-.38 5.094-.38 7.641 0a1.778 1.778 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.76-1.465-1.547-.35-2.657-.205-5.343.352-8a1.774 1.774 0 011.463-1.401z" />
        <path d="M.966 24.816c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM15.311 14.948c2.547-.38 5.094-.38 7.641 0a1.779 1.779 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.76-1.465-1.547-.322-2.657-.175-5.343.381-8a1.775 1.775 0 011.434-1.401z" />
        <path d="M14.14 24.816c3.337-.088 6.674-.088 10.012 0 .878.438 1.053.905.526 1.343-3.688.087-7.377.087-11.065 0-.527-.438-.352-.905.527-1.343zM28.485 14.948c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0-.79-.117-1.405-.76-1.464-1.547-.322-2.657-.176-5.343.38-8a1.775 1.775 0 011.435-1.401z" />
        <path d="M27.313 24.816c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM41.658 14.948c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029c.087-.7.673-1.255 1.375-1.372z" />
        <path d="M40.487 24.816c3.337-.088 6.674-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.378.087-11.066 0-.527-.438-.351-.905.527-1.343zM2.166 29.487c2.547-.38 5.094-.38 7.641 0a1.778 1.778 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.76-1.465-1.547-.35-2.686-.205-5.343.352-8.029.146-.7.732-1.255 1.463-1.372z" />
        <path d="M.966 39.355c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM15.311 29.487c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0-.79-.117-1.405-.76-1.464-1.547a26.486 26.486 0 01.44-8.029c.087-.7.672-1.255 1.375-1.372z" />
        <path d="M14.14 39.355c3.337-.088 6.674-.088 10.012 0 .878.438 1.053.905.526 1.343-3.688.087-7.377.087-11.065 0-.527-.467-.352-.905.527-1.343zM28.485 29.487a25.78 25.78 0 017.64 0 1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.089.788-.703 1.43-1.465 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.76-1.464-1.547a26.483 26.483 0 01.439-8.029c.088-.7.673-1.255 1.376-1.372z" />
        <path d="M27.313 39.355c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM41.658 29.487c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029c.087-.7.673-1.255 1.375-1.372z" />
        <path d="M40.487 39.355c3.337-.088 6.674-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.378.087-11.066 0-.527-.467-.351-.905.527-1.343zM2.167 44.026c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0-.79-.117-1.405-.759-1.464-1.547a26.483 26.483 0 01.44-8.029c.058-.73.643-1.285 1.375-1.372z" />
        <path d="M.966 53.865c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM15.311 44.026c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0-.79-.117-1.405-.759-1.464-1.547a26.486 26.486 0 01.44-8.029 1.582 1.582 0 011.375-1.372z" />
        <path d="M14.14 53.865c3.337-.088 6.674-.088 10.012 0 .878.438 1.053.905.526 1.343-3.688.087-7.377.087-11.065 0-.527-.438-.352-.905.527-1.343zM28.485 44.026c2.547-.38 5.093-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.089.788-.703 1.43-1.465 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029 1.582 1.582 0 011.376-1.372z" />
        <path d="M27.313 53.865c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM41.658 44.026c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029 1.583 1.583 0 011.375-1.372z" />
        <path d="M40.487 53.865c3.337-.088 6.674-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.378.087-11.066 0-.527-.438-.351-.905.527-1.343zM2.167 58.536c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029c.058-.7.643-1.255 1.375-1.372z" />
        <path d="M.966 68.404c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM15.311 58.536c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.486 26.486 0 01.44-8.029c.087-.7.672-1.255 1.375-1.372z" />
        <path d="M14.14 68.404c3.337-.088 6.674-.088 10.012 0 .878.438 1.053.905.526 1.343-3.688.087-7.377.087-11.065 0-.527-.467-.352-.905.527-1.343zM28.485 58.536a25.78 25.78 0 017.64 0 1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.089.788-.703 1.43-1.465 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.673-1.255 1.376-1.372z" />
        <path d="M27.313 68.404c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM41.658 58.536c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029c.087-.7.673-1.255 1.375-1.372z" />
        <path d="M40.487 68.404c3.337-.088 6.674-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.378.087-11.066 0-.527-.467-.351-.905.527-1.343zM2.166 73.075c2.547-.38 5.094-.38 7.641 0a1.778 1.778 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.759-1.465-1.547-.35-2.686-.205-5.372.352-8.029a1.707 1.707 0 011.463-1.372z" />
        <path d="M.966 82.914c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.906.527-1.343zM15.311 73.075c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0-.79-.117-1.405-.759-1.464-1.547a26.486 26.486 0 01.44-8.029 1.583 1.583 0 011.375-1.372z" />
        <path d="M14.14 82.914c3.337-.088 6.674-.088 10.012 0 .878.438 1.053.905.526 1.343-3.688.087-7.377.087-11.065 0-.527-.438-.352-.906.527-1.343zM28.485 73.075a25.78 25.78 0 017.64 0 1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.089.788-.703 1.43-1.465 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029 1.583 1.583 0 011.376-1.372z" />
        <path d="M27.313 82.914c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.438-.351-.906.527-1.343zM41.658 73.075c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029 1.583 1.583 0 011.375-1.372z" />
        <path d="M40.487 82.914c3.337-.088 6.674-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.378.087-11.066 0-.527-.438-.351-.906.527-1.343zM2.167 87.585c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0-.79-.117-1.405-.76-1.464-1.547a26.483 26.483 0 01.44-8.029c.058-.7.643-1.255 1.375-1.372z" />
        <path d="M.966 97.453c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.468-.351-.905.527-1.343zM15.311 87.585c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0-.79-.117-1.405-.76-1.464-1.547a26.486 26.486 0 01.44-8.029c.087-.7.672-1.255 1.375-1.372z" />
        <path d="M14.14 97.453c3.337-.088 6.674-.088 10.012 0 .878.438 1.053.905.526 1.343-3.688.087-7.377.087-11.065 0-.527-.468-.352-.905.527-1.343zM28.485 87.585a25.78 25.78 0 017.64 0 1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.089.788-.703 1.43-1.465 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.76-1.464-1.547a26.483 26.483 0 01.439-8.029c.088-.7.673-1.255 1.376-1.372z" />
        <path d="M27.313 97.453c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.468-.351-.905.527-1.343zM41.658 87.585c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.465 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.44-8.029c.087-.7.673-1.255 1.375-1.372z" />
        <path d="M40.487 97.453c3.337-.088 6.674-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.378.087-11.066 0-.527-.468-.351-.905.527-1.343zM63.673.438a25.78 25.78 0 017.64 0 1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M62.501 10.306c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.467-.35-.905.527-1.343zM76.846.438c2.547-.38 5.094-.38 7.641 0a1.778 1.778 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.465-1.547 26.486 26.486 0 01.44-8.029c.087-.7.644-1.255 1.375-1.372z" />
        <path d="M75.675 10.306c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM90.02.438a25.78 25.78 0 017.64 0 1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M88.849 10.306c3.337-.088 6.674-.088 10.011 0 .879.438 1.054.905.527 1.343-3.688.087-7.377.087-11.065 0-.527-.467-.352-.905.526-1.343zM103.194.438c2.546-.38 5.093-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.71 1.71 0 01-1.464-1.547 26.474 26.474 0 01.44-8.029c.058-.7.644-1.255 1.376-1.372z" />
        <path d="M102.022 10.306c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM63.673 14.948c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0-.79-.117-1.406-.76-1.464-1.547a26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M62.501 24.816c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.438-.35-.905.527-1.343zM76.846 14.948a25.78 25.78 0 017.641 0 1.779 1.779 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.465-1.547 26.486 26.486 0 01.44-8.029c.087-.7.644-1.255 1.375-1.372z" />
        <path d="M75.675 24.816c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM90.02 14.948c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M88.849 24.816c3.337-.088 6.674-.088 10.011 0 .879.438 1.054.905.527 1.343-3.688.087-7.377.087-11.065 0-.527-.438-.352-.905.526-1.343zM103.194 14.948c2.546-.38 5.093-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.71 1.71 0 01-1.464-1.547 26.474 26.474 0 01.44-8.029c.058-.7.644-1.255 1.376-1.372z" />
        <path d="M102.022 24.816c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM63.673 29.487c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0-.79-.117-1.406-.76-1.464-1.547a26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M62.501 39.355c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.467-.35-.905.527-1.343zM76.846 29.487a25.78 25.78 0 017.641 0 1.779 1.779 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.76-1.465-1.547a26.486 26.486 0 01.44-8.029c.087-.7.644-1.255 1.375-1.372z" />
        <path d="M75.675 39.355c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM90.02 29.487c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M88.849 39.355c3.337-.088 6.674-.088 10.011 0 .879.438 1.054.905.527 1.343a233.27 233.27 0 01-11.065 0c-.527-.467-.352-.905.526-1.343zM103.194 29.487c2.546-.38 5.093-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.71 1.71 0 01-1.464-1.547 26.474 26.474 0 01.44-8.029c.058-.7.644-1.255 1.376-1.372z" />
        <path d="M102.022 39.355c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM63.673 44.026c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0-.79-.117-1.406-.759-1.464-1.547a26.483 26.483 0 01.439-8.029 1.554 1.554 0 011.376-1.372z" />
        <path d="M62.501 53.865c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.438-.35-.905.527-1.343zM76.846 44.026c2.547-.38 5.094-.38 7.641 0a1.778 1.778 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.465-1.547 26.486 26.486 0 01.44-8.029 1.554 1.554 0 011.375-1.372z" />
        <path d="M75.675 53.865c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM90.02 44.026c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029 1.554 1.554 0 011.376-1.372z" />
        <path d="M88.849 53.865c3.337-.088 6.674-.088 10.011 0 .879.438 1.054.905.527 1.343-3.688.087-7.377.087-11.065 0-.527-.438-.352-.905.526-1.343zM103.194 44.026a25.77 25.77 0 017.64 0 1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.71 1.71 0 01-1.464-1.547 26.474 26.474 0 01.44-8.029c.058-.73.644-1.285 1.376-1.372z" />
        <path d="M102.022 53.865c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.905.527-1.343zM63.673 58.536c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M62.501 68.404c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.467-.35-.905.527-1.343zM76.846 58.536a25.78 25.78 0 017.641 0 1.779 1.779 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.465-1.547 26.486 26.486 0 01.44-8.029c.087-.7.644-1.255 1.375-1.372z" />
        <path d="M75.675 68.404c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM90.02 58.536c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M88.849 68.404c3.337-.088 6.674-.088 10.011 0 .879.438 1.054.905.527 1.343-3.688.087-7.377.087-11.065 0-.527-.467-.352-.905.526-1.343zM103.194 58.536c2.546-.38 5.093-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.71 1.71 0 01-1.464-1.547 26.474 26.474 0 01.44-8.029c.058-.7.644-1.255 1.376-1.372z" />
        <path d="M102.022 68.404c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.467-.351-.905.527-1.343zM63.673 73.075c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0-.79-.117-1.406-.759-1.464-1.547a26.483 26.483 0 01.439-8.029 1.554 1.554 0 011.376-1.372z" />
        <path d="M62.501 82.914c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.438-.35-.906.527-1.343zM76.846 73.075a25.78 25.78 0 017.641 0 1.779 1.779 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0a1.709 1.709 0 01-1.465-1.547 26.486 26.486 0 01.44-8.029 1.554 1.554 0 011.375-1.372z" />
        <path d="M75.675 82.914c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.906.527-1.343zM90.02 73.075c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029 1.554 1.554 0 011.376-1.372z" />
        <path d="M88.849 82.914c3.337-.088 6.674-.088 10.011 0 .879.438 1.054.905.527 1.343-3.688.087-7.377.087-11.065 0-.527-.438-.352-.906.526-1.343zM103.194 73.075c2.546-.38 5.093-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.71 1.71 0 01-1.464-1.547 26.474 26.474 0 01.44-8.029c.058-.73.644-1.285 1.376-1.372z" />
        <path d="M102.022 82.914c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.438-.351-.906.527-1.343zM63.673 87.585c2.547-.38 5.094-.38 7.64 0a1.779 1.779 0 011.464 1.372c.556 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0-.79-.117-1.406-.76-1.464-1.547a26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M62.501 97.453c3.338-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.688.087-7.377.087-11.066 0-.527-.468-.35-.905.527-1.343zM76.846 87.585a25.78 25.78 0 017.641 0 1.779 1.779 0 011.464 1.372c.556 2.686.702 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.43 0-.791-.117-1.406-.76-1.465-1.547a26.486 26.486 0 01.44-8.029c.087-.7.644-1.255 1.375-1.372z" />
        <path d="M75.675 97.453c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.468-.351-.905.527-1.343zM90.02 87.585c2.547-.38 5.094-.38 7.64 0a1.778 1.778 0 011.464 1.372c.557 2.686.703 5.343.44 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.621.438-8.431 0a1.709 1.709 0 01-1.464-1.547 26.483 26.483 0 01.439-8.029c.088-.7.644-1.255 1.376-1.372z" />
        <path d="M88.849 97.453c3.337-.088 6.674-.088 10.011 0 .879.438 1.054.905.527 1.343-3.688.087-7.377.087-11.065 0-.527-.468-.352-.905.526-1.343zM103.194 87.585c2.546-.38 5.093-.38 7.64 0a1.778 1.778 0 011.464 1.372c.556 2.686.703 5.343.439 8.029-.088.788-.703 1.43-1.464 1.547-2.81.438-5.62.438-8.431 0a1.71 1.71 0 01-1.464-1.547 26.474 26.474 0 01.44-8.029c.058-.7.644-1.255 1.376-1.372z" />
        <path d="M102.022 97.453c3.337-.088 6.675-.088 10.012 0 .878.438 1.054.905.527 1.343-3.689.087-7.377.087-11.066 0-.527-.468-.351-.905.527-1.343z" />
      </g>
      <defs>
        <clipPath id="theater">
          <path fill="#fff" d="M0 0h113v99H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Theater.propTypes = {};

Theater.defaultProps = {};

export default Theater;
