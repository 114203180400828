import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'styles/theme';

const Svg = styled.svg`
  height: 100%;
  max-height: 55px;
  ${media.ns} {
    max-height: 88px;
    max-width: initial;
  }
`;

function Circular(props) {
  return (
    <Svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88 88"
      {...props}
    >
      <g
        clipPath="url(#circular)"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      >
        <path d="M32.335 16.084c-4.775.964-9.55.964-14.325 0-1.256-5.026-1.507-10.052-.754-15.078a40.29 40.29 0 0115.833 0c.754 5.026.503 10.052-.754 15.078zM51.183 16.084c-4.775.963-9.55.963-14.324 0-1.257-5.026-1.508-10.053-.754-15.079a40.29 40.29 0 0115.832 0c.754 5.026.503 10.053-.754 15.079zM70.031 16.084c-4.774.963-9.55.963-14.324 0-1.257-5.026-1.508-10.053-.754-15.079a40.29 40.29 0 0115.832 0c.754 5.026.503 10.053-.754 15.079z" />
        <path d="M70.031 2.22c-4.774.126-9.55.126-14.324 0C54.45 1.592 54.199.92 54.953.293a332.532 332.532 0 0115.832 0c.754.67.503 1.299-.754 1.927zM51.183 2.22c-4.775.126-9.55.126-14.324 0C35.602 1.592 35.35.92 36.105.293a332.527 332.527 0 0115.832 0c.754.67.503 1.299-.754 1.927zM32.335 2.22c-4.775.126-9.55.126-14.325 0-1.256-.628-1.507-1.299-.754-1.927a332.532 332.532 0 0115.833 0c.754.67.503 1.299-.754 1.927zM55.707 71.916a35.863 35.863 0 0114.324 0c1.257 5.026 1.508 10.053.754 15.079a40.291 40.291 0 01-15.832 0c-.754-5.026-.503-10.053.754-15.079zM36.859 71.916a35.863 35.863 0 0114.324 0c1.257 5.026 1.508 10.053.754 15.079a40.29 40.29 0 01-15.832 0c-.754-5.026-.503-10.053.754-15.079zM18.01 71.916a35.863 35.863 0 0114.325 0c1.257 5.026 1.508 10.053.754 15.079a40.291 40.291 0 01-15.832 0c-.754-5.026-.503-10.053.753-15.079z" />
        <path d="M18.01 85.78c4.775-.126 9.55-.126 14.325 0 1.257.628 1.508 1.299.754 1.927a332.41 332.41 0 01-15.832 0c-.754-.628-.503-1.299.753-1.927zM36.859 85.78c4.774-.126 9.55-.126 14.324 0 1.257.628 1.508 1.299.754 1.927-5.277.126-10.555.126-15.832 0-.754-.628-.503-1.299.754-1.927zM55.707 85.78c4.775-.126 9.55-.126 14.324 0 1.257.628 1.508 1.299.754 1.927a332.41 332.41 0 01-15.832 0c-.754-.628-.503-1.299.754-1.927zM71.916 32.335a35.863 35.863 0 010-14.325c5.026-1.256 10.053-1.507 15.079-.753a40.29 40.29 0 010 15.832c-5.026.754-10.053.503-15.079-.754zM71.916 51.183a35.863 35.863 0 010-14.324c5.026-1.257 10.053-1.508 15.079-.754a40.291 40.291 0 010 15.832c-5.026.754-10.053.503-15.079-.754zM71.916 70.031a35.863 35.863 0 010-14.324c5.026-1.257 10.053-1.508 15.079-.754a40.291 40.291 0 010 15.832c-5.026.754-10.053.503-15.079-.754z" />
        <path d="M85.78 70.031c-.126-4.774-.126-9.55 0-14.324.628-1.257 1.299-1.508 1.927-.754.126 5.277.126 10.555 0 15.832-.628.754-1.299.503-1.927-.754zM85.78 51.183c-.126-4.775-.126-9.55 0-14.324.628-1.257 1.299-1.508 1.927-.754.126 5.277.126 10.555 0 15.832-.628.754-1.299.503-1.927-.754zM85.78 32.335c-.126-4.775-.126-9.55 0-14.325.628-1.256 1.299-1.507 1.927-.753a332.67 332.67 0 010 15.832c-.628.754-1.299.503-1.927-.754zM16.084 55.707a35.86 35.86 0 010 14.324c-5.027 1.257-10.053 1.508-15.079.754a40.29 40.29 0 010-15.832c5.026-.754 10.052-.503 15.079.754zM16.084 36.859a35.86 35.86 0 010 14.324c-5.027 1.257-10.053 1.508-15.079.754a40.29 40.29 0 010-15.832c5.026-.754 10.052-.503 15.079.754zM16.084 18.01c.964 4.775.964 9.55 0 14.325-5.026 1.257-10.052 1.508-15.078.754a40.29 40.29 0 010-15.832c5.026-.754 10.052-.503 15.078.753z" />
        <path d="M2.22 18.01c.126 4.775.126 9.55 0 14.325-.628 1.257-1.299 1.508-1.927.754a332.527 332.527 0 010-15.832c.67-.754 1.299-.503 1.927.753zM2.22 36.859c.126 4.774.126 9.55 0 14.324-.628 1.257-1.299 1.508-1.927.754a332.532 332.532 0 010-15.832c.67-.754 1.299-.503 1.927.754zM2.22 55.707c.126 4.775.126 9.55 0 14.324-.628 1.257-1.299 1.508-1.927.754a332.532 332.532 0 010-15.832c.67-.754 1.299-.503 1.927.754zM72.921 74.178h-57.8a1.234 1.234 0 01-1.257-1.257v-57.8c0-.713.544-1.257 1.256-1.257h57.801c.712 0 1.257.544 1.257 1.256v57.801a1.26 1.26 0 01-1.257 1.257z" />
      </g>
      <defs>
        <clipPath id="circular">
          <path fill="#fff" d="M0 0h88v88H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}

Circular.propTypes = {};

Circular.defaultProps = {};

export default Circular;
