import * as React from 'react';
// import PropTypes from 'prop-types';

function Council(props) {
  return (
    <svg
      viewBox="0 0 119 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#council)"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      >
        <path d="M19.268 39.938c.863 0 1.696-.524 2.004-1.358C24.602 30 40.448 23.488 59.5 23.488c19.052 0 34.898 6.512 38.228 15.092.308.834 1.11 1.358 2.004 1.358h10.605C108.179 27.5 86.26 17.716 59.5 17.716S10.821 27.5 8.663 39.938h10.605zM65.85 12.438h-12.7a.586.586 0 01-.587-.586V.74c0-.34.278-.587.586-.587h12.702c.339 0 .585.278.585.587v11.08c0 .34-.277.617-.585.617z" />
        <path d="M64.463 13.179h-9.926a.586.586 0 01-.586-.586V2.099c0-.34.277-.587.586-.587h9.926c.34 0 .586.278.586.587v10.494c0 .34-.246.586-.586.586zM87.77 14.599H75.07a.586.586 0 01-.586-.587V2.932c0-.34.277-.586.586-.586H87.77c.34 0 .586.277.586.586v11.08c0 .34-.247.587-.586.587z" />
        <path d="M86.414 15.37h-9.958a.586.586 0 01-.586-.586V4.29c0-.34.278-.586.586-.586h9.927c.339 0 .586.277.586.586v10.494c.03.34-.247.586-.555.586zM43.9 14.599H31.2a.586.586 0 01-.586-.587V2.932c0-.34.278-.586.586-.586H43.9c.34 0 .586.277.586.586v11.08c0 .34-.246.587-.585.587z" />
        <path d="M42.544 15.37h-9.927a.586.586 0 01-.586-.586V4.29c0-.34.278-.586.586-.586h9.927c.34 0 .586.277.586.586v10.494c0 .34-.278.586-.586.586zM109.196 22.191H96.495a.586.586 0 01-.586-.586V10.494c0-.34.277-.587.586-.587h12.701c.339 0 .586.278.586.587v11.08c0 .34-.277.617-.586.617z" />
        <path d="M107.809 22.932h-9.927a.586.586 0 01-.586-.586V11.852c0-.34.278-.587.586-.587h9.927c.339 0 .586.278.586.587v10.494c0 .34-.247.586-.586.586zM22.505 22.191H9.804a.586.586 0 01-.586-.586V10.494c0-.34.277-.587.586-.587h12.701c.34 0 .586.278.586.587v11.08c0 .34-.277.617-.586.617z" />
        <path d="M21.118 22.932H11.19a.586.586 0 01-.586-.586V11.852c0-.34.278-.587.586-.587h9.927c.339 0 .586.278.586.587v10.494c0 .34-.247.586-.586.586zM2.282 46.852a27.04 27.04 0 018.046 0c.771.123 1.388.71 1.542 1.45.586 2.84.74 5.649.462 8.488-.092.834-.74 1.513-1.541 1.636-2.96.463-5.92.463-8.879 0A1.804 1.804 0 01.371 56.79a28.103 28.103 0 01.462-8.487c.062-.741.678-1.328 1.449-1.451z" />
        <path d="M1.017 57.284a200.12 200.12 0 0110.544 0c.925.463 1.11.957.555 1.42-3.885.092-7.77.092-11.654 0-.555-.494-.37-.957.555-1.42zM16.123 46.852a27.04 27.04 0 018.047 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.649.463 8.488-.093.834-.74 1.513-1.542 1.636-2.96.463-5.919.463-8.878 0a1.804 1.804 0 01-1.542-1.636 28.104 28.104 0 01.463-8.487c.092-.741.709-1.328 1.448-1.451z" />
        <path d="M14.89 57.284c3.515-.093 7.03-.093 10.544 0 .925.463 1.11.957.555 1.42-3.885.092-7.77.092-11.654 0-.555-.494-.37-.957.555-1.42zM29.997 46.852a27.04 27.04 0 018.046 0c.77.123 1.387.71 1.541 1.45a28.1 28.1 0 01.463 8.488c-.093.834-.74 1.513-1.542 1.636-2.96.463-5.919.463-8.878 0a1.804 1.804 0 01-1.542-1.636 28.104 28.104 0 01.463-8.487c.092-.741.709-1.328 1.449-1.451z" />
        <path d="M28.763 57.284a200.12 200.12 0 0110.544 0c.925.463 1.11.957.555 1.42-3.885.092-7.769.092-11.654 0-.554-.494-.37-.957.555-1.42zM43.87 46.852a27.04 27.04 0 018.046 0c.77.123 1.387.71 1.542 1.45.585 2.84.74 5.649.462 8.488-.093.834-.74 1.513-1.542 1.636a28.55 28.55 0 01-8.878 0 1.804 1.804 0 01-1.542-1.636 28.104 28.104 0 01.463-8.487c.092-.741.709-1.328 1.449-1.451z" />
        <path d="M42.636 57.284c3.515-.093 7.03-.093 10.544 0 .925.463 1.11.957.555 1.42-3.885.092-7.769.092-11.653 0-.555-.494-.37-.957.554-1.42zM2.282 62.222a27.046 27.046 0 018.046 0c.771.124 1.388.71 1.542 1.45.586 2.84.74 5.649.462 8.489-.092.833-.74 1.512-1.541 1.635-2.96.463-5.92.463-8.879 0A1.804 1.804 0 01.371 72.16a28.104 28.104 0 01.462-8.487c.062-.772.678-1.358 1.449-1.45z" />
        <path d="M1.017 72.624a200.12 200.12 0 0110.544 0c.925.462 1.11.956.555 1.42-3.885.092-7.77.092-11.654 0-.555-.464-.37-.958.555-1.42zM16.123 62.222a27.047 27.047 0 018.047 0c.77.124 1.387.71 1.541 1.45.586 2.84.74 5.649.463 8.489-.093.833-.74 1.512-1.542 1.635-2.96.463-5.919.463-8.878 0a1.804 1.804 0 01-1.542-1.636 28.105 28.105 0 01.463-8.487 1.67 1.67 0 011.448-1.45z" />
        <path d="M14.89 72.624c3.515-.093 7.03-.093 10.544 0 .925.462 1.11.956.555 1.42-3.885.092-7.77.092-11.654 0-.555-.464-.37-.958.555-1.42zM29.997 62.222a27.047 27.047 0 018.046 0c.77.124 1.387.71 1.541 1.45.586 2.84.74 5.649.463 8.489-.093.833-.74 1.512-1.542 1.635-2.96.463-5.919.463-8.878 0a1.804 1.804 0 01-1.542-1.636 28.104 28.104 0 01.463-8.487 1.67 1.67 0 011.449-1.45z" />
        <path d="M28.763 72.624a200.12 200.12 0 0110.544 0c.925.462 1.11.956.555 1.42-3.885.092-7.769.092-11.654 0-.554-.464-.37-.958.555-1.42zM43.87 62.222a27.047 27.047 0 018.046 0c.77.124 1.387.71 1.542 1.45.585 2.84.74 5.649.462 8.489-.093.833-.74 1.512-1.542 1.635a28.55 28.55 0 01-8.878 0 1.804 1.804 0 01-1.542-1.636 28.105 28.105 0 01.463-8.487 1.67 1.67 0 011.449-1.45z" />
        <path d="M42.636 72.624c3.515-.093 7.03-.093 10.544 0 .925.462 1.11.956.555 1.42-3.885.092-7.769.092-11.653 0-.555-.464-.37-.958.554-1.42zM2.282 77.562a27.047 27.047 0 018.047 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.648.462 8.488-.092.833-.74 1.512-1.541 1.636-2.96.463-5.92.463-8.879 0A1.804 1.804 0 01.371 87.5C0 84.691.155 81.852.74 79.012c.154-.74.77-1.327 1.541-1.45z" />
        <path d="M1.017 87.994a200.12 200.12 0 0110.544 0c.925.463 1.11.957.555 1.42-3.885.092-7.77.092-11.654 0-.555-.494-.37-.957.555-1.42zM16.123 77.562a27.047 27.047 0 018.047 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.648.463 8.488-.093.833-.74 1.512-1.542 1.636-2.96.463-5.919.463-8.878 0a1.804 1.804 0 01-1.542-1.636 28.105 28.105 0 01.463-8.488c.092-.74.709-1.327 1.448-1.45z" />
        <path d="M14.89 87.994c3.515-.093 7.03-.093 10.544 0 .925.463 1.11.957.555 1.42-3.885.092-7.77.092-11.654 0-.555-.494-.37-.957.555-1.42zM29.997 77.562a27.047 27.047 0 018.046 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.648.463 8.488-.093.833-.74 1.512-1.542 1.636-2.96.463-5.919.463-8.878 0a1.804 1.804 0 01-1.542-1.636 28.105 28.105 0 01.463-8.488c.092-.74.709-1.327 1.449-1.45z" />
        <path d="M28.763 87.994a200.12 200.12 0 0110.544 0c.925.463 1.11.957.555 1.42-3.885.092-7.769.092-11.654 0-.554-.494-.37-.957.555-1.42zM43.87 77.562a27.047 27.047 0 018.046 0c.77.123 1.387.71 1.542 1.45.585 2.84.74 5.648.462 8.488-.093.833-.74 1.512-1.542 1.636a28.55 28.55 0 01-8.878 0 1.804 1.804 0 01-1.542-1.636 28.105 28.105 0 01.463-8.488c.092-.74.709-1.327 1.449-1.45z" />
        <path d="M42.636 87.994c3.515-.093 7.03-.093 10.544 0 .925.463 1.11.957.555 1.42-3.885.092-7.769.092-11.653 0-.555-.494-.37-.957.554-1.42zM2.282 92.932a27.046 27.046 0 018.046 0c.771.124 1.388.71 1.542 1.45.586 2.84.74 5.649.462 8.488-.092.834-.74 1.513-1.541 1.636-2.96.463-5.92.463-8.879 0a1.803 1.803 0 01-1.541-1.636 28.102 28.102 0 01.462-8.487c.062-.772.678-1.358 1.449-1.45z" />
        <path d="M1.017 103.333c3.515-.092 7.03-.092 10.544 0 .925.463 1.11.957.555 1.42-3.885.093-7.77.093-11.654 0-.555-.463-.37-.926.555-1.42zM16.123 92.932a27.047 27.047 0 018.047 0c.77.124 1.387.71 1.541 1.45.586 2.84.74 5.649.463 8.488-.093.834-.74 1.513-1.542 1.636-2.96.463-5.919.463-8.878 0a1.803 1.803 0 01-1.542-1.636 28.103 28.103 0 01.463-8.487 1.67 1.67 0 011.448-1.45z" />
        <path d="M14.89 103.333c3.515-.092 7.03-.092 10.544 0 .925.463 1.11.957.555 1.42-3.885.093-7.77.093-11.654 0-.555-.463-.37-.926.555-1.42zM29.997 92.932a27.047 27.047 0 018.046 0c.77.124 1.387.71 1.541 1.45a28.1 28.1 0 01.463 8.488c-.093.834-.74 1.513-1.542 1.636-2.96.463-5.919.463-8.878 0a1.803 1.803 0 01-1.542-1.636 28.103 28.103 0 01.463-8.487 1.67 1.67 0 011.449-1.45z" />
        <path d="M28.763 103.333c3.515-.092 7.03-.092 10.544 0 .925.463 1.11.957.555 1.42-3.885.093-7.769.093-11.654 0-.554-.463-.37-.926.555-1.42zM43.87 92.932a27.047 27.047 0 018.046 0c.77.124 1.387.71 1.542 1.45.585 2.84.74 5.649.462 8.488-.093.834-.74 1.513-1.542 1.636a28.55 28.55 0 01-8.878 0 1.803 1.803 0 01-1.542-1.636 28.103 28.103 0 01.463-8.487 1.67 1.67 0 011.449-1.45z" />
        <path d="M42.636 103.333c3.515-.092 7.03-.092 10.544 0 .925.463 1.11.957.555 1.42-3.885.093-7.769.093-11.653 0-.555-.463-.37-.926.554-1.42zM67.053 46.852a27.04 27.04 0 018.047 0c.77.123 1.387.71 1.54 1.45.587 2.84.74 5.649.463 8.488-.092.834-.74 1.513-1.541 1.636a28.55 28.55 0 01-8.879 0 1.804 1.804 0 01-1.541-1.636 28.104 28.104 0 01.462-8.487c.093-.741.678-1.328 1.45-1.451z" />
        <path d="M65.82 57.284a200.12 200.12 0 0110.544 0c.924.463 1.11.957.554 1.42-3.884.092-7.769.092-11.653 0-.555-.494-.37-.957.555-1.42zM80.926 46.852a27.04 27.04 0 018.046 0c.771.123 1.388.71 1.542 1.45.586 2.84.74 5.649.462 8.488-.092.834-.74 1.513-1.541 1.636-2.96.463-5.92.463-8.879 0a1.803 1.803 0 01-1.541-1.636 28.1 28.1 0 01.462-8.487c.093-.741.678-1.328 1.45-1.451z" />
        <path d="M79.693 57.284c3.514-.093 7.029-.093 10.544 0 .924.463 1.11.957.554 1.42-3.884.092-7.769.092-11.653 0-.555-.494-.37-.957.555-1.42zM94.8 46.852a27.043 27.043 0 018.046 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.649.462 8.488-.092.834-.739 1.513-1.541 1.636a28.55 28.55 0 01-8.879 0 1.804 1.804 0 01-1.541-1.636 28.104 28.104 0 01.462-8.487c.093-.741.678-1.328 1.45-1.451z" />
        <path d="M93.566 57.284c3.514-.093 7.029-.093 10.544 0 .924.463 1.109.957.554 1.42-3.884.092-7.768.092-11.653 0-.555-.494-.37-.957.555-1.42zM108.672 46.852a27.044 27.044 0 018.047 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.649.463 8.488-.093.834-.74 1.513-1.542 1.636-2.96.463-5.919.463-8.879 0a1.804 1.804 0 01-1.541-1.636 28.11 28.11 0 01.462-8.487c.062-.741.679-1.328 1.449-1.451z" />
        <path d="M107.439 57.284c3.515-.093 7.029-.093 10.544 0 .924.463 1.109.957.555 1.42-3.885.092-7.769.092-11.654 0-.555-.494-.37-.957.555-1.42zM67.053 62.222a27.047 27.047 0 018.047 0c.77.124 1.387.71 1.54 1.45.587 2.84.74 5.649.463 8.489-.092.833-.74 1.512-1.541 1.635a28.55 28.55 0 01-8.879 0 1.804 1.804 0 01-1.541-1.636 28.104 28.104 0 01.462-8.487 1.64 1.64 0 011.45-1.45z" />
        <path d="M65.82 72.624a200.12 200.12 0 0110.544 0c.924.462 1.11.956.554 1.42-3.884.092-7.769.092-11.653 0-.555-.464-.37-.958.555-1.42zM80.926 62.222a27.046 27.046 0 018.046 0c.771.124 1.388.71 1.542 1.45.586 2.84.74 5.649.462 8.489-.092.833-.74 1.512-1.541 1.635-2.96.463-5.92.463-8.879 0a1.804 1.804 0 01-1.541-1.636 28.101 28.101 0 01.462-8.487 1.64 1.64 0 011.45-1.45z" />
        <path d="M79.693 72.624c3.514-.093 7.029-.093 10.544 0 .924.462 1.11.956.554 1.42-3.884.092-7.769.092-11.653 0-.555-.464-.37-.958.555-1.42zM94.8 62.222a27.05 27.05 0 018.046 0c.77.124 1.387.71 1.541 1.45.586 2.84.74 5.649.462 8.489-.092.833-.739 1.512-1.541 1.635a28.55 28.55 0 01-8.879 0 1.804 1.804 0 01-1.541-1.636 28.104 28.104 0 01.462-8.487 1.64 1.64 0 011.45-1.45z" />
        <path d="M93.566 72.624c3.514-.093 7.029-.093 10.544 0 .924.462 1.109.956.554 1.42-3.884.092-7.768.092-11.653 0-.555-.464-.37-.958.555-1.42zM108.672 62.222a27.05 27.05 0 018.047 0c.77.124 1.387.71 1.541 1.45.586 2.84.74 5.649.463 8.489-.093.833-.74 1.512-1.542 1.635-2.96.463-5.919.463-8.879 0a1.804 1.804 0 01-1.541-1.636 28.11 28.11 0 01.462-8.487c.062-.772.679-1.358 1.449-1.45z" />
        <path d="M107.439 72.624c3.515-.093 7.029-.093 10.544 0 .924.462 1.109.956.555 1.42-3.885.092-7.769.092-11.654 0-.555-.464-.37-.958.555-1.42zM67.053 77.562a27.047 27.047 0 018.047 0c.77.123 1.387.71 1.54 1.45.587 2.84.74 5.648.463 8.488-.092.833-.74 1.512-1.541 1.636a28.55 28.55 0 01-8.879 0 1.804 1.804 0 01-1.541-1.636 28.105 28.105 0 01.462-8.488c.093-.74.678-1.327 1.45-1.45z" />
        <path d="M65.82 87.994a200.12 200.12 0 0110.544 0c.924.463 1.11.957.554 1.42-3.884.092-7.769.092-11.653 0-.555-.494-.37-.957.555-1.42zM80.926 77.562a27.046 27.046 0 018.046 0c.771.123 1.388.71 1.542 1.45.586 2.84.74 5.648.462 8.488-.092.833-.74 1.512-1.541 1.636-2.96.463-5.92.463-8.879 0a1.804 1.804 0 01-1.541-1.636 28.101 28.101 0 01.462-8.488c.093-.74.678-1.327 1.45-1.45z" />
        <path d="M79.693 87.994c3.514-.093 7.029-.093 10.544 0 .924.463 1.11.957.554 1.42-3.884.092-7.769.092-11.653 0-.555-.494-.37-.957.555-1.42zM94.8 77.562a27.05 27.05 0 018.046 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.648.462 8.488-.092.833-.739 1.512-1.541 1.636a28.55 28.55 0 01-8.879 0 1.804 1.804 0 01-1.541-1.636 28.105 28.105 0 01.462-8.488c.093-.74.678-1.327 1.45-1.45z" />
        <path d="M93.566 87.994c3.514-.093 7.029-.093 10.544 0 .924.463 1.109.957.554 1.42-3.884.092-7.768.092-11.653 0-.555-.494-.37-.957.555-1.42zM108.672 77.562a27.05 27.05 0 018.047 0c.77.123 1.387.71 1.541 1.45.586 2.84.74 5.648.463 8.488-.093.833-.74 1.512-1.542 1.636-2.96.463-5.919.463-8.879 0a1.804 1.804 0 01-1.541-1.636 28.11 28.11 0 01.462-8.488c.062-.74.679-1.327 1.449-1.45z" />
        <path d="M107.439 87.994c3.515-.093 7.029-.093 10.544 0 .924.463 1.109.957.555 1.42-3.885.092-7.769.092-11.654 0-.555-.494-.37-.957.555-1.42zM67.053 92.932a27.047 27.047 0 018.047 0c.77.124 1.387.71 1.54 1.45.587 2.84.74 5.649.463 8.488-.092.834-.74 1.513-1.541 1.636a28.55 28.55 0 01-8.879 0 1.803 1.803 0 01-1.541-1.636 28.103 28.103 0 01.462-8.487 1.64 1.64 0 011.45-1.45z" />
        <path d="M65.82 103.333c3.515-.092 7.029-.092 10.544 0 .924.463 1.11.957.554 1.42-3.884.093-7.769.093-11.653 0-.555-.463-.37-.926.555-1.42zM80.926 92.932a27.046 27.046 0 018.046 0c.771.124 1.388.71 1.542 1.45.586 2.84.74 5.649.462 8.488-.092.834-.74 1.513-1.541 1.636-2.96.463-5.92.463-8.879 0a1.803 1.803 0 01-1.541-1.636 28.1 28.1 0 01.462-8.487 1.64 1.64 0 011.45-1.45z" />
        <path d="M79.693 103.333c3.514-.092 7.029-.092 10.544 0 .924.463 1.11.957.554 1.42-3.884.093-7.769.093-11.653 0-.555-.463-.37-.926.555-1.42zM94.8 92.932a27.05 27.05 0 018.046 0c.77.124 1.387.71 1.541 1.45.586 2.84.74 5.649.462 8.488-.092.834-.739 1.513-1.541 1.636a28.55 28.55 0 01-8.879 0 1.803 1.803 0 01-1.541-1.636 28.103 28.103 0 01.462-8.487 1.64 1.64 0 011.45-1.45z" />
        <path d="M93.566 103.333a201.44 201.44 0 0110.544 0c.924.463 1.109.957.554 1.42-3.884.093-7.768.093-11.653 0-.555-.463-.37-.926.555-1.42zM108.672 92.932a27.05 27.05 0 018.047 0c.77.124 1.387.71 1.541 1.45.586 2.84.74 5.649.463 8.488-.093.834-.74 1.513-1.542 1.636-2.96.463-5.919.463-8.879 0a1.803 1.803 0 01-1.541-1.636 28.109 28.109 0 01.462-8.487c.062-.772.679-1.358 1.449-1.45z" />
        <path d="M107.439 103.333a201.44 201.44 0 0110.544 0c.924.463 1.109.957.555 1.42-3.885.093-7.769.093-11.654 0-.555-.463-.37-.926.555-1.42z" />
      </g>
      <defs>
        <clipPath id="council">
          <path fill="#fff" d="M0 0h119v105H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Council.propTypes = {};

Council.defaultProps = {};

export default Council;
