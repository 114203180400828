import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/theme';

const Svg = styled.svg`
  height: 100%;
  max-height: 54px;
  ${media.ns} {
    max-height: 90px;
    max-width: initial;
  }
`;

function Breakout(props) {
  return (
    <Svg
      viewBox="0 0 135 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#breakout)">
        <path
          d="M7.47733 82.4625C6.36132 81.3375 5.43131 80.1375 4.65011 78.7875C4.2781 78.15 3.38529 78.0375 2.86449 78.5625L0.558069 80.8875C0.111665 81.3375 0.111665 82.0875 0.558069 82.5375L7.36573 89.475C7.81213 89.925 8.55614 89.925 9.00255 89.475L11.309 87.15C11.8298 86.625 11.7182 85.725 11.0858 85.35C9.78375 84.525 8.55614 83.5875 7.47733 82.4625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M32.6247 57.1125C33.7407 58.2375 34.6707 59.4375 35.4519 60.7875C35.8239 61.425 36.7167 61.5375 37.2375 61.0125L39.544 58.6875C39.9904 58.2375 39.9904 57.4875 39.544 57.0375L32.7363 50.1C32.2899 49.65 31.5459 49.65 31.0995 50.1L28.7931 52.425C28.2723 52.95 28.3839 53.85 29.0163 54.225C30.2811 55.05 31.5087 55.9875 32.6247 57.1125Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M37.2376 78.6C36.7168 78.075 35.824 78.1875 35.452 78.825C34.6708 80.1375 33.7036 81.375 32.6248 82.5C31.5088 83.625 30.3184 84.5625 28.9791 85.35C28.3467 85.725 28.2351 86.625 28.7559 87.15L31.0624 89.475C31.5088 89.925 32.2528 89.925 32.6992 89.475L39.544 82.575C39.9904 82.125 39.9904 81.375 39.544 80.925L37.2376 78.6Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M2.82726 60.975C3.34806 61.5 4.24087 61.3875 4.61287 60.75C5.39408 59.4375 6.36129 58.2 7.4401 57.075C8.55611 55.95 9.74652 55.0125 11.0857 54.225C11.7181 53.85 11.8297 52.95 11.3089 52.425L9.00251 50.1C8.55611 49.65 7.8121 49.65 7.3657 50.1L0.520838 57C0.074434 57.45 0.074434 58.2 0.520838 58.65L2.82726 60.975Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M8.85364 81.075C7.62603 79.8375 6.54722 78.45 5.69161 76.9875C5.2824 76.275 4.278 76.1625 3.68279 76.7625L1.07877 79.3875C0.595163 79.875 0.595163 80.7 1.07877 81.225L8.74204 88.9125C9.22564 89.4 10.044 89.4 10.5649 88.9125L13.1689 86.2875C13.7641 85.6875 13.6525 84.7125 12.9457 84.2625C11.4577 83.4 10.0812 82.3125 8.85364 81.075Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M31.2112 58.5375C32.4388 59.775 33.5176 61.1625 34.3732 62.625C34.7824 63.3375 35.7868 63.45 36.382 62.85L38.986 60.225C39.4696 59.7375 39.4696 58.9125 38.986 58.3875L31.3228 50.6625C30.8392 50.175 30.0208 50.175 29.4999 50.6625L26.8959 53.2875C26.3007 53.8875 26.4123 54.8625 27.1191 55.3125C28.6071 56.2125 29.9836 57.2625 31.2112 58.5375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M36.3819 76.725C35.7867 76.125 34.8195 76.2375 34.3731 76.95C33.4803 78.4125 32.4387 79.8 31.2111 81.0375C29.9835 82.275 28.607 83.3625 27.1562 84.225C26.4494 84.6375 26.3378 85.65 26.933 86.25L29.5371 88.875C30.0207 89.3625 30.8391 89.3625 31.3599 88.875L39.0231 81.15C39.5067 80.6625 39.5067 79.8375 39.0231 79.3125L36.3819 76.725Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M3.68279 62.85C4.278 63.45 5.2452 63.3375 5.69161 62.625C6.58442 61.1625 7.62603 59.775 8.85364 58.5375C10.0812 57.3 11.4577 56.2125 12.9085 55.35C13.6153 54.9375 13.7269 53.925 13.1317 53.325L10.5277 50.7C10.044 50.2125 9.22564 50.2125 8.70483 50.7L1.07877 58.3875C0.595163 58.875 0.595163 59.7 1.07877 60.225L3.68279 62.85Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M12.4622 86.6625C14.8058 87.75 17.3726 88.3125 20.051 88.3125C22.7295 88.3125 25.2591 87.75 27.6399 86.6625C29.6487 85.7625 31.4715 84.4875 33.0712 82.875C34.6708 81.2625 35.9356 79.425 36.8284 77.4C37.9072 75.0375 38.4652 72.45 38.4652 69.75C38.4652 67.05 37.9072 64.5 36.8284 62.1C35.9356 60.075 34.6708 58.2375 33.0712 56.625C31.4715 55.0125 29.6487 53.7375 27.6399 52.8375C25.2963 51.75 22.7295 51.1875 20.051 51.1875C17.4098 51.1875 14.843 51.75 12.4622 52.8375C10.4533 53.7375 8.63053 55.0125 7.03092 56.625C5.4313 58.2375 4.16649 60.075 3.27369 62.1C2.23208 64.5375 1.67407 67.125 1.67407 69.7875C1.67407 72.45 2.23208 75.075 3.31089 77.4375C4.20369 79.425 5.46851 81.3 7.06812 82.875C8.63053 84.4875 10.4906 85.7625 12.4622 86.6625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M7.47733 32.8875C6.36132 31.7625 5.43131 30.5625 4.65011 29.2125C4.2781 28.575 3.38529 28.4625 2.86449 28.9875L0.558069 31.3125C0.111665 31.7625 0.111665 32.5125 0.558069 32.9625L7.40293 39.8625C7.84933 40.3125 8.59334 40.3125 9.03974 39.8625L11.3462 37.5375C11.867 37.0125 11.7554 36.1125 11.123 35.7375C9.78375 34.95 8.55614 33.975 7.47733 32.8875Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M32.6248 7.5375C33.7408 8.6625 34.6708 9.8625 35.452 11.2125C35.824 11.85 36.7168 11.9625 37.2376 11.4375L39.5441 9.1125C39.9905 8.6625 39.9905 7.9125 39.5441 7.4625L32.7364 0.524993C32.29 0.074992 31.546 0.074992 31.0996 0.524993L28.7932 2.85C28.2724 3.375 28.384 4.27499 29.0164 4.64999C30.2812 5.43749 31.5088 6.4125 32.6248 7.5375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M37.2376 29.025C36.7168 28.5 35.824 28.6125 35.452 29.25C34.6708 30.5625 33.7036 31.8 32.6248 32.925C31.5088 34.05 30.3184 34.9875 28.9792 35.775C28.3467 36.15 28.2351 37.05 28.7559 37.575L31.0624 39.9C31.5088 40.35 32.2528 40.35 32.6992 39.9L39.544 33C39.9904 32.55 39.9904 31.8 39.544 31.35L37.2376 29.025Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M2.82726 11.4C3.34806 11.925 4.24087 11.8125 4.61287 11.175C5.39408 9.86253 6.36129 8.62503 7.4401 7.50002C8.55611 6.37502 9.74652 5.43752 11.0857 4.65002C11.7181 4.27502 11.8297 3.37503 11.3089 2.85003L9.00251 0.525022C8.55611 0.0750223 7.8121 0.0750224 7.3657 0.525022L0.520838 7.42503C0.074434 7.87503 0.074434 8.62502 0.520838 9.07502L2.82726 11.4Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M8.85364 31.4625C7.62603 30.225 6.54722 28.8375 5.69161 27.375C5.2824 26.6625 4.278 26.55 3.68279 27.15L1.07877 29.775C0.595163 30.2625 0.595163 31.0875 1.07877 31.6125L8.74204 39.3375C9.22564 39.825 10.044 39.825 10.5649 39.3375L13.1689 36.7125C13.7641 36.1125 13.6525 35.1375 12.9457 34.6875C11.4577 33.7875 10.0812 32.7375 8.85364 31.4625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M31.2112 8.92502C32.4388 10.1625 33.5176 11.55 34.3732 13.0125C34.7824 13.725 35.7868 13.8375 36.382 13.2375L38.986 10.6125C39.4696 10.125 39.4696 9.30001 38.986 8.77501L31.3228 1.05003C30.8392 0.562527 30.0208 0.562527 29.4999 1.05003L26.8959 3.67502C26.3007 4.27502 26.4123 5.25002 27.1191 5.70002C28.6071 6.60002 29.9835 7.68752 31.2112 8.92502Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M36.3819 27.15C35.7867 26.55 34.8195 26.6625 34.3731 27.375C33.4803 28.8375 32.4387 30.225 31.2111 31.4625C29.9835 32.7 28.607 33.7875 27.1562 34.65C26.4494 35.0625 26.3378 36.075 26.933 36.675L29.5371 39.3C30.0207 39.7875 30.8391 39.7875 31.3599 39.3L39.0231 31.575C39.5068 31.0875 39.5068 30.2625 39.0231 29.7375L36.3819 27.15Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M3.68279 13.2375C4.278 13.8375 5.2452 13.725 5.69161 13.0125C6.58442 11.55 7.62603 10.1625 8.85364 8.92501C10.0812 7.68751 11.4577 6.60001 12.9085 5.73751C13.6153 5.32501 13.7269 4.3125 13.1317 3.7125L10.5277 1.0875C10.044 0.600002 9.22564 0.600002 8.70483 1.0875L1.07877 8.81251C0.595163 9.30001 0.595163 10.125 1.07877 10.65L3.68279 13.2375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M12.4621 37.0875C14.8058 38.175 17.3726 38.7375 20.051 38.7375C22.7294 38.7375 25.2591 38.175 27.6399 37.0875C29.6487 36.1875 31.4715 34.9125 33.0711 33.3C34.6707 31.6875 35.9355 29.85 36.8284 27.825C37.9072 25.4625 38.4652 22.875 38.4652 20.175C38.4652 17.5125 37.9072 14.925 36.8284 12.525C35.9355 10.5 34.6707 8.66249 33.0711 7.04999C31.4715 5.43749 29.6487 4.16249 27.6399 3.26249C25.2963 2.17499 22.7294 1.61249 20.051 1.61249C17.4098 1.61249 14.843 2.17499 12.4621 3.26249C10.4533 4.16249 8.6305 5.43749 7.03089 7.04999C5.43127 8.66249 4.16646 10.5 3.27365 12.525C2.19484 14.8875 1.63684 17.475 1.63684 20.175C1.63684 22.8375 2.19485 25.425 3.27365 27.825C4.16646 29.85 5.43127 31.6875 7.03089 33.3C8.6305 34.9125 10.4905 36.15 12.4621 37.0875Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M54.945 82.4625C53.829 81.3375 52.899 80.1375 52.1178 78.7875C51.7458 78.15 50.8529 78.0375 50.3321 78.5625L48.0257 80.8875C47.5793 81.3375 47.5793 82.0875 48.0257 82.5375L54.8334 89.475C55.2798 89.925 56.0238 89.925 56.4702 89.475L58.7766 87.15C59.2974 86.625 59.1858 85.725 58.5534 85.35C57.2514 84.525 56.0238 83.5875 54.945 82.4625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M80.0924 57.1125C81.2084 58.2375 82.1385 59.4375 82.9197 60.7875C83.2917 61.425 84.1845 61.5375 84.7053 61.0125L87.0117 58.6875C87.4581 58.2375 87.4581 57.4875 87.0117 57.0375L80.1668 50.1375C79.7204 49.6875 78.9764 49.6875 78.53 50.1375L76.2236 52.4625C75.7028 52.9875 75.8144 53.8875 76.4468 54.2625C77.7488 55.05 78.9764 55.9875 80.0924 57.1125Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M84.7053 78.6C84.1845 78.075 83.2917 78.1875 82.9197 78.825C82.1385 80.1375 81.1712 81.375 80.0924 82.5C78.9764 83.625 77.786 84.5625 76.4468 85.35C75.8144 85.725 75.7028 86.625 76.2236 87.15L78.53 89.475C78.9764 89.925 79.7204 89.925 80.1668 89.475L87.0117 82.575C87.4581 82.125 87.4581 81.375 87.0117 80.925L84.7053 78.6Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M50.2949 60.975C50.8157 61.5 51.7085 61.3875 52.0805 60.75C52.8617 59.4375 53.8289 58.2 54.9078 57.075C56.0238 55.95 57.2142 55.0125 58.5534 54.225C59.1858 53.85 59.2974 52.95 58.7766 52.425L56.4702 50.1C56.0238 49.65 55.2798 49.65 54.8333 50.1L47.9885 57C47.5421 57.45 47.5421 58.2 47.9885 58.65L50.2949 60.975Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M56.3214 81.075C55.0938 79.8375 54.015 78.45 53.1594 76.9875C52.7502 76.275 51.7458 76.1625 51.1506 76.7625L48.5465 79.3875C48.0629 79.875 48.0629 80.7 48.5465 81.225L56.2098 88.9125C56.6934 89.4 57.5118 89.4 58.0326 88.9125L60.6367 86.2875C61.2319 85.6875 61.1203 84.7125 60.4135 84.2625C58.9254 83.4 57.549 82.3125 56.3214 81.075Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M78.6787 58.5375C79.9063 59.775 80.9851 61.1625 81.8407 62.625C82.2499 63.3375 83.2543 63.45 83.8496 62.85L86.4536 60.225C86.9372 59.7375 86.9372 58.9125 86.4536 58.3875L78.7903 50.6625C78.3067 50.175 77.4883 50.175 76.9675 50.6625L74.3635 53.2875C73.7682 53.8875 73.8799 54.8625 74.5867 55.3125C76.0747 56.2125 77.4511 57.2625 78.6787 58.5375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M83.8496 76.725C83.2544 76.125 82.2872 76.2375 81.8407 76.95C80.9479 78.4125 79.9063 79.8 78.6787 81.0375C77.4511 82.275 76.0747 83.3625 74.6239 84.225C73.9171 84.6375 73.8055 85.65 74.4007 86.25L77.0047 88.875C77.4883 89.3625 78.3067 89.3625 78.8275 88.875L86.4908 81.15C86.9744 80.6625 86.9744 79.8375 86.4908 79.3125L83.8496 76.725Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M51.1505 62.85C51.7457 63.45 52.7129 63.3375 53.1594 62.625C54.0522 61.1625 55.0938 59.775 56.3214 58.5375C57.549 57.3 58.9254 56.2125 60.3762 55.35C61.083 54.9375 61.1946 53.925 60.5994 53.325L57.9954 50.7C57.5118 50.2125 56.6934 50.2125 56.1726 50.7L48.5093 58.425C48.0257 58.9125 48.0257 59.7375 48.5093 60.2625L51.1505 62.85Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M59.9298 86.6625C62.2734 87.75 64.8402 88.3125 67.5187 88.3125C70.1971 88.3125 72.7267 87.75 75.1075 86.6625C77.1163 85.7625 78.9392 84.4875 80.5388 82.875C82.1384 81.2625 83.4032 79.425 84.296 77.4C85.3748 75.0375 85.9328 72.45 85.9328 69.75C85.9328 67.05 85.3748 64.5 84.296 62.1C83.4032 60.075 82.1384 58.2375 80.5388 56.625C78.9392 55.0125 77.1163 53.7375 75.1075 52.8375C72.7639 51.75 70.1971 51.1875 67.5187 51.1875C64.8774 51.1875 62.3106 51.75 59.9298 52.8375C57.921 53.7375 56.0982 55.0125 54.4985 56.625C52.8989 58.2375 51.6341 60.075 50.7413 62.1C49.6625 64.4625 49.1045 67.05 49.1045 69.75C49.1045 72.45 49.6625 75 50.7413 77.4C51.6341 79.425 52.8989 81.2625 54.4985 82.875C56.0982 84.4875 57.9582 85.7625 59.9298 86.6625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M54.945 32.8875C53.829 31.7625 52.899 30.5625 52.1178 29.2125C51.7458 28.575 50.8529 28.4625 50.3321 28.9875L48.0257 31.3125C47.5793 31.7625 47.5793 32.5125 48.0257 32.9625L54.8706 39.8625C55.317 40.3125 56.061 40.3125 56.5074 39.8625L58.8138 37.5375C59.3346 37.0125 59.223 36.1125 58.5906 35.7375C57.2514 34.95 56.0238 33.975 54.945 32.8875Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M80.0924 7.5375C81.2084 8.6625 82.1384 9.8625 82.9197 11.2125C83.2917 11.85 84.1845 11.9625 84.7053 11.4375L87.0117 9.1125C87.4581 8.6625 87.4581 7.9125 87.0117 7.4625L80.1668 0.562506C79.7204 0.112506 78.9764 0.112506 78.53 0.562506L76.2236 2.8875C75.7028 3.4125 75.8144 4.3125 76.4468 4.6875C77.7488 5.4375 78.9764 6.41251 80.0924 7.5375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M84.7053 29.025C84.1845 28.5 83.2917 28.6125 82.9197 29.25C82.1384 30.5625 81.1712 31.8 80.0924 32.925C78.9764 34.05 77.786 34.9875 76.4468 35.775C75.8144 36.15 75.7028 37.05 76.2236 37.575L78.53 39.9C78.9764 40.35 79.7204 40.35 80.1668 39.9L87.0117 33C87.4581 32.55 87.4581 31.8 87.0117 31.35L84.7053 29.025Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M50.2949 11.4C50.8157 11.925 51.7085 11.8125 52.0805 11.175C52.8617 9.86246 53.8289 8.62496 54.9077 7.49996C56.0238 6.37496 57.2142 5.43746 58.5534 4.64996C59.1858 4.27496 59.2974 3.37497 58.7766 2.84997L56.4702 0.524961C56.0238 0.0749613 55.2797 0.0749613 54.8333 0.524961L47.9885 7.42497C47.5421 7.87497 47.5421 8.62496 47.9885 9.07496L50.2949 11.4Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M56.3214 31.4625C55.0938 30.225 54.015 28.8375 53.1594 27.375C52.7502 26.6625 51.7458 26.55 51.1506 27.15L48.5465 29.775C48.0629 30.2625 48.0629 31.0875 48.5465 31.6125L56.2098 39.3375C56.6934 39.825 57.5118 39.825 58.0326 39.3375L60.6367 36.7125C61.2319 36.1125 61.1203 35.1375 60.4135 34.6875C58.9254 33.7875 57.549 32.7375 56.3214 31.4625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M78.6788 8.92501C79.9064 10.1625 80.9852 11.55 81.8409 13.0125C82.2501 13.725 83.2545 13.8375 83.8497 13.2375L86.4537 10.6125C86.9373 10.125 86.9373 9.30001 86.4537 8.77501L78.7904 1.05002C78.3068 0.562519 77.4884 0.562519 76.9676 1.05002L74.3636 3.67502C73.7684 4.27502 73.88 5.25001 74.5868 5.70001C76.0748 6.60001 77.4512 7.68752 78.6788 8.92501Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M83.8496 27.15C83.2544 26.5499 82.2872 26.6625 81.8408 27.375C80.9479 28.8375 79.9063 30.225 78.6787 31.4625C77.4511 32.7 76.0747 33.7875 74.6239 34.65C73.9171 35.0625 73.8055 36.075 74.4007 36.675L77.0047 39.3C77.4883 39.7875 78.3067 39.7875 78.8275 39.3L86.4908 31.575C86.9744 31.0875 86.9744 30.2625 86.4908 29.7375L83.8496 27.15Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M51.1505 13.2375C51.7457 13.8375 52.7129 13.725 53.1594 13.0125C54.0522 11.55 55.0938 10.1625 56.3214 8.92501C57.549 7.68751 58.9254 6.60001 60.3762 5.73751C61.083 5.32501 61.1946 4.3125 60.5994 3.7125L57.9954 1.0875C57.5118 0.600002 56.6934 0.600002 56.1726 1.0875L48.5093 8.81251C48.0257 9.30001 48.0257 10.125 48.5093 10.65L51.1505 13.2375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M59.9298 37.0875C62.2734 38.175 64.8402 38.7375 67.5187 38.7375C70.1971 38.7375 72.7267 38.175 75.1075 37.0875C77.1163 36.1875 78.9392 34.9125 80.5388 33.3C82.1384 31.6875 83.4032 29.85 84.296 27.825C85.3748 25.4625 85.9328 22.875 85.9328 20.175C85.9328 17.5125 85.3748 14.925 84.296 12.525C83.4032 10.5 82.1384 8.66249 80.5388 7.04999C78.9392 5.43749 77.1163 4.16249 75.1075 3.26249C72.7639 2.17499 70.1971 1.61249 67.5187 1.61249C64.8774 1.61249 62.3106 2.17499 59.9298 3.26249C57.921 4.16249 56.0982 5.43749 54.4985 7.04999C52.8989 8.66249 51.6341 10.5 50.7413 12.525C49.6625 14.8875 49.1045 17.475 49.1045 20.175C49.1045 22.8375 49.6625 25.425 50.7413 27.825C51.6341 29.85 52.8989 31.6875 54.4985 33.3C56.0982 34.9125 57.9582 36.15 59.9298 37.0875Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M102.375 82.4625C101.259 81.3375 100.329 80.1375 99.5481 78.7875C99.176 78.15 98.2832 78.0375 97.7624 78.5625L95.456 80.8875C95.0096 81.3375 95.0096 82.0875 95.456 82.5375L102.301 89.475C102.747 89.925 103.491 89.925 103.938 89.475L106.244 87.15C106.765 86.625 106.653 85.725 106.021 85.35C104.719 84.525 103.491 83.5875 102.375 82.4625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M127.56 57.1125C128.676 58.2375 129.606 59.4375 130.387 60.7875C130.759 61.425 131.652 61.5375 132.173 61.0125L134.479 58.6875C134.926 58.2375 134.926 57.4875 134.479 57.0375L127.634 50.1375C127.188 49.6875 126.444 49.6875 125.998 50.1375L123.691 52.4625C123.17 52.9875 123.282 53.8875 123.914 54.2625C125.216 55.05 126.444 55.9875 127.56 57.1125Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M132.173 78.6C131.652 78.075 130.759 78.1875 130.387 78.825C129.606 80.1375 128.639 81.375 127.56 82.5C126.444 83.625 125.254 84.5625 123.914 85.35C123.282 85.725 123.17 86.625 123.691 87.15L125.998 89.475C126.444 89.925 127.188 89.925 127.634 89.475L134.479 82.575C134.926 82.125 134.926 81.375 134.479 80.925L132.173 78.6Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M97.7624 60.975C98.2832 61.5 99.176 61.3875 99.5481 60.75C100.329 59.4375 101.296 58.2 102.375 57.075C103.491 55.95 104.682 55.0125 106.021 54.225C106.653 53.85 106.765 52.95 106.244 52.425L103.938 50.1C103.491 49.65 102.747 49.65 102.301 50.1L95.456 57C95.0096 57.45 95.0096 58.2 95.456 58.65L97.7624 60.975Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M103.789 81.075C102.561 79.8375 101.483 78.45 100.627 76.9875C100.218 76.275 99.2133 76.1625 98.6181 76.7625L96.0141 79.3875C95.5305 79.875 95.5305 80.7 96.0141 81.225L103.677 88.9125C104.161 89.4 104.979 89.4 105.5 88.9125L108.104 86.2875C108.699 85.6875 108.588 84.7125 107.881 84.2625C106.393 83.4 105.017 82.3125 103.789 81.075Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M126.146 58.5375C127.374 59.775 128.453 61.1625 129.308 62.625C129.717 63.3375 130.722 63.45 131.317 62.85L133.921 60.225C134.405 59.7375 134.405 58.9125 133.921 58.3875L126.258 50.6625C125.774 50.175 124.956 50.175 124.435 50.6625L121.831 53.2875C121.236 53.8875 121.347 54.8625 122.054 55.3125C123.542 56.2125 124.919 57.2625 126.146 58.5375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M131.317 76.725C130.722 76.125 129.755 76.2375 129.308 76.95C128.415 78.4125 127.374 79.8 126.146 81.0375C124.919 82.275 123.542 83.3625 122.091 84.225C121.385 84.6375 121.273 85.65 121.868 86.25L124.472 88.875C124.956 89.3625 125.774 89.3625 126.295 88.875L133.921 81.1875C134.405 80.7 134.405 79.875 133.921 79.35L131.317 76.725Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M98.6181 62.85C99.2133 63.45 100.18 63.3375 100.627 62.625C101.52 61.1625 102.561 59.775 103.789 58.5375C105.017 57.3 106.393 56.2125 107.844 55.35C108.551 54.9375 108.662 53.925 108.067 53.325L105.463 50.7C104.979 50.2125 104.161 50.2125 103.64 50.7L95.9768 58.425C95.4932 58.9125 95.4932 59.7375 95.9768 60.2625L98.6181 62.85Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M107.397 86.6625C109.741 87.75 112.308 88.3125 114.986 88.3125C117.627 88.3125 120.194 87.75 122.575 86.6625C124.584 85.7625 126.407 84.4875 128.006 82.875C129.606 81.2625 130.871 79.425 131.764 77.4C132.842 75.0375 133.4 72.45 133.4 69.75C133.4 67.05 132.842 64.5 131.764 62.1C130.871 60.075 129.606 58.2375 128.006 56.625C126.407 55.0125 124.584 53.7375 122.575 52.8375C120.231 51.75 117.665 51.1875 114.986 51.1875C112.345 51.1875 109.778 51.75 107.397 52.8375C105.388 53.7375 103.566 55.0125 101.966 56.625C100.366 58.2375 99.1016 60.075 98.2088 62.1C97.13 64.4625 96.572 67.05 96.572 69.75C96.572 72.45 97.13 75 98.2088 77.4C99.1016 79.425 100.366 81.2625 101.966 82.875C103.566 84.4875 105.389 85.7625 107.397 86.6625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M102.375 32.8867C101.259 31.7617 100.329 30.5617 99.5481 29.2117C99.176 28.5742 98.2832 28.4617 97.7624 28.9867L95.456 31.3117C95.0096 31.7617 95.0096 32.5117 95.456 32.9617L102.301 39.8617C102.747 40.3117 103.491 40.3117 103.938 39.8617L106.244 37.5367C106.765 37.0117 106.653 36.1117 106.021 35.7367C104.719 34.9492 103.491 33.9742 102.375 32.8867Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M127.56 7.5375C128.676 8.6625 129.606 9.8625 130.387 11.2125C130.759 11.85 131.652 11.9625 132.173 11.4375L134.479 9.1125C134.926 8.6625 134.926 7.9125 134.479 7.4625L127.634 0.562506C127.188 0.112506 126.444 0.112506 125.998 0.562506L123.691 2.8875C123.17 3.4125 123.282 4.3125 123.914 4.6875C125.216 5.4375 126.444 6.41251 127.56 7.5375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M132.173 29.025C131.652 28.5 130.759 28.6125 130.387 29.25C129.606 30.5625 128.639 31.8 127.56 32.925C126.444 34.05 125.254 34.9875 123.914 35.775C123.282 36.15 123.17 37.05 123.691 37.575L125.998 39.9C126.444 40.35 127.188 40.35 127.634 39.9L134.479 33C134.926 32.55 134.926 31.8 134.479 31.35L132.173 29.025Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M97.7624 11.4C98.2832 11.925 99.176 11.8125 99.5481 11.175C100.329 9.86252 101.296 8.62502 102.375 7.50002C103.491 6.37502 104.682 5.43752 106.021 4.65002C106.653 4.27502 106.765 3.37502 106.244 2.85002L103.938 0.525015C103.491 0.0750147 102.747 0.0750147 102.301 0.525016L95.456 7.42502C95.0096 7.87502 95.0096 8.62502 95.456 9.07502L97.7624 11.4Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M103.789 31.4625C102.561 30.225 101.483 28.8375 100.627 27.375C100.218 26.6625 99.2133 26.55 98.6181 27.15L96.0141 29.775C95.5305 30.2625 95.5305 31.0875 96.0141 31.6125L103.677 39.3375C104.161 39.825 104.979 39.825 105.5 39.3375L108.104 36.7125C108.699 36.1125 108.588 35.1375 107.881 34.6875C106.393 33.7875 105.017 32.7375 103.789 31.4625Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M126.146 8.92501C127.374 10.1625 128.453 11.55 129.308 13.0125C129.717 13.725 130.722 13.8375 131.317 13.2375L133.921 10.6125C134.405 10.125 134.405 9.30001 133.921 8.77501L126.258 1.05002C125.774 0.562519 124.956 0.562519 124.435 1.05002L121.831 3.67502C121.236 4.27502 121.347 5.25001 122.054 5.70001C123.542 6.60001 124.919 7.68752 126.146 8.92501Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M131.317 27.15C130.722 26.55 129.755 26.6625 129.308 27.375C128.415 28.8375 127.374 30.225 126.146 31.4625C124.919 32.7 123.542 33.7875 122.091 34.65C121.385 35.0625 121.273 36.075 121.868 36.675L124.472 39.3C124.956 39.7875 125.774 39.7875 126.295 39.3L133.958 31.575C134.442 31.0875 134.442 30.2625 133.958 29.7375L131.317 27.15Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M98.6181 13.2375C99.2133 13.8375 100.18 13.725 100.627 13.0125C101.52 11.55 102.561 10.1625 103.789 8.92501C105.017 7.68751 106.393 6.6 107.844 5.7375C108.551 5.325 108.662 4.3125 108.067 3.7125L105.463 1.0875C104.979 0.599995 104.161 0.599995 103.64 1.0875L95.9768 8.81251C95.4932 9.30001 95.4932 10.125 95.9768 10.65L98.6181 13.2375Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M107.397 37.0875C109.741 38.175 112.308 38.7375 114.986 38.7375C117.627 38.7375 120.194 38.175 122.575 37.0875C124.584 36.1875 126.407 34.9125 128.006 33.3C129.606 31.6875 130.871 29.85 131.764 27.825C132.842 25.4625 133.4 22.875 133.4 20.175C133.4 17.5125 132.842 14.925 131.764 12.525C130.871 10.5 129.606 8.66248 128.006 7.04998C126.407 5.43748 124.584 4.16248 122.575 3.26248C120.231 2.17498 117.665 1.61248 114.986 1.61248C112.345 1.61248 109.778 2.17498 107.397 3.26248C105.388 4.16248 103.566 5.43748 101.966 7.04999C100.366 8.66248 99.1016 10.5 98.2088 12.525C97.13 14.8875 96.572 17.475 96.572 20.175C96.572 22.8375 97.13 25.425 98.2088 27.825C99.1016 29.85 100.366 31.6875 101.966 33.3C103.566 34.9125 105.389 36.15 107.397 37.0875Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="breakout">
          <rect
            width="90"
            height="135"
            fill="white"
            transform="translate(0 90) rotate(-90)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

Breakout.propTypes = {};

Breakout.defaultProps = {};

export default Breakout;
