import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/theme';

const Svg = styled.svg`
  height: 100%;
  max-height: 54px;
  ${media.ns} {
    max-height: 96px;
    max-width: initial;
  }
`;

function Flexible(props) {
  return (
    <Svg
      viewBox="0 0 159 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M140.441 51.863a39.722 39.722 0 010-11.805c.181-1.131 1.04-2.036 2.126-2.262 4.161-.86 8.277-1.085 12.438-.678 1.221.135 2.216 1.085 2.397 2.261a41.905 41.905 0 010 13.026c-.18 1.222-1.176 2.172-2.397 2.262-4.161.407-8.277.18-12.438-.678-1.086-.091-1.945-.996-2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M155.729 53.718a293.287 293.287 0 010-15.469c.678-1.357 1.402-1.628 2.081-.814.135 5.699.135 11.398 0 17.097-.724.814-1.403.543-2.081-.814zM140.441 72.941a39.722 39.722 0 010-11.805c.181-1.13 1.04-2.035 2.126-2.261 4.161-.86 8.277-1.086 12.438-.679 1.221.136 2.216 1.086 2.397 2.262a41.905 41.905 0 010 13.026c-.181 1.221-1.176 2.171-2.397 2.262-4.161.407-8.277.18-12.438-.679-1.086-.09-1.945-.995-2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M155.729 74.796a293.287 293.287 0 010-15.468c.678-1.357 1.402-1.629 2.08-.814.136 5.699.136 11.398 0 17.097-.723.814-1.402.542-2.08-.815zM140.441 93.941a39.722 39.722 0 010-11.805c.181-1.13 1.04-2.035 2.126-2.261 4.161-.86 8.277-1.086 12.438-.679 1.221.136 2.216 1.086 2.397 2.262a41.905 41.905 0 010 13.026c-.181 1.221-1.176 2.171-2.397 2.262-4.161.407-8.277.18-12.438-.679-1.086-.09-1.945-.995-2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M155.729 95.796a293.287 293.287 0 010-15.468c.678-1.357 1.402-1.629 2.08-.814.136 5.699.136 11.398 0 17.097-.723.814-1.402.542-2.08-.815zM88.115 37.441a39.722 39.722 0 0111.805 0c1.131.18 2.036 1.04 2.262 2.126.859 4.161 1.085 8.277.678 12.438-.135 1.221-1.085 2.216-2.261 2.397a41.937 41.937 0 01-13.026 0c-1.222-.18-2.172-1.176-2.262-2.397-.407-4.161-.181-8.277.678-12.438.09-1.086.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M86.26 52.729a294.163 294.163 0 0115.469 0c1.357.678 1.628 1.402.814 2.08-5.7.136-11.398.136-17.097 0-.814-.723-.543-1.402.814-2.08zM67.786 37.441a39.721 39.721 0 0111.805 0c1.13.18 2.035 1.04 2.262 2.126.859 4.161 1.085 8.277.678 12.438-.136 1.221-1.085 2.216-2.261 2.397a41.936 41.936 0 01-13.027 0c-1.22-.18-2.17-1.176-2.261-2.397-.407-4.161-.181-8.277.678-12.438.09-1.086.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M65.93 52.729a293.935 293.935 0 0115.47 0c1.356.678 1.628 1.402.814 2.08-5.7.136-11.398.136-17.097 0-.814-.723-.543-1.402.814-2.08zM47.457 37.441a39.721 39.721 0 0111.805 0c1.13.18 2.035 1.04 2.261 2.126.86 4.161 1.086 8.277.679 12.438-.136 1.221-1.086 2.216-2.262 2.397a41.936 41.936 0 01-13.026 0c-1.221-.18-2.171-1.176-2.262-2.397-.407-4.161-.18-8.277.679-12.438.09-1.086.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M45.602 52.729a293.935 293.935 0 0115.468 0c1.357.678 1.629 1.402.814 2.08-5.699.136-11.398.136-17.097 0-.814-.723-.542-1.402.815-2.08zM27.127 37.441a39.721 39.721 0 0111.805 0c1.131.18 2.036 1.04 2.262 2.126.86 4.161 1.085 8.277.678 12.438-.135 1.221-1.085 2.216-2.261 2.397a41.936 41.936 0 01-13.026 0c-1.222-.18-2.171-1.176-2.262-2.397-.407-4.161-.18-8.277.678-12.438.091-1.086.996-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M25.272 52.729a293.935 293.935 0 0115.469 0c1.357.678 1.628 1.402.814 2.08-5.699.136-11.398.136-17.097 0-.814-.723-.543-1.402.814-2.08zM88.115 58.518a39.722 39.722 0 0111.805 0c1.131.181 2.036 1.04 2.262 2.126.859 4.161 1.085 8.277.678 12.438-.136 1.222-1.085 2.217-2.261 2.398a41.944 41.944 0 01-13.027 0c-1.22-.181-2.17-1.176-2.261-2.398-.407-4.16-.181-8.277.678-12.438.09-1.085.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M86.26 73.806a293.95 293.95 0 0115.469 0c1.357.678 1.628 1.402.814 2.08-5.7.136-11.398.136-17.097 0-.814-.723-.543-1.401.814-2.08zM67.786 58.518a39.721 39.721 0 0111.805 0c1.13.181 2.035 1.04 2.262 2.126.859 4.161 1.085 8.277.678 12.438-.136 1.222-1.086 2.217-2.262 2.398a41.942 41.942 0 01-13.026 0c-1.221-.181-2.17-1.176-2.261-2.398-.407-4.16-.181-8.277.678-12.438.09-1.085.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M65.93 73.806a293.935 293.935 0 0115.47 0c1.356.678 1.628 1.402.813 2.08-5.698.136-11.397.136-17.096 0-.815-.723-.543-1.401.814-2.08zM47.456 58.518a39.721 39.721 0 0111.805 0c1.131.181 2.036 1.04 2.262 2.126.86 4.161 1.086 8.277.678 12.438-.135 1.222-1.085 2.217-2.261 2.398a41.942 41.942 0 01-13.026 0c-1.221-.181-2.171-1.176-2.262-2.398-.407-4.16-.18-8.277.679-12.438.09-1.085.995-1.945 2.125-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M45.601 73.806a293.935 293.935 0 0115.47 0c1.356.678 1.627 1.402.813 2.08-5.699.136-11.398.136-17.097 0-.814-.723-.542-1.402.814-2.08zM27.127 58.518a39.722 39.722 0 0111.805 0c1.131.181 2.036 1.04 2.262 2.126.86 4.161 1.085 8.277.678 12.438-.135 1.222-1.085 2.217-2.261 2.398a41.942 41.942 0 01-13.026 0c-1.222-.181-2.172-1.176-2.262-2.398-.407-4.16-.18-8.277.678-12.438.09-1.085.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M25.272 73.806a293.935 293.935 0 0115.469 0c1.357.678 1.628 1.402.814 2.08-5.699.136-11.398.136-17.097 0-.814-.723-.543-1.402.814-2.08zM88.115 79.596a39.722 39.722 0 0111.805 0c1.131.18 2.036 1.04 2.262 2.125.859 4.161 1.085 8.277.678 12.439-.136 1.22-1.085 2.216-2.261 2.397a41.944 41.944 0 01-13.027 0c-1.22-.181-2.17-1.176-2.261-2.397-.407-4.162-.181-8.278.678-12.439.09-1.085.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M86.26 94.883a293.95 293.95 0 0115.469 0c1.357.679 1.628 1.402.814 2.08-5.7.137-11.398.137-17.097 0-.814-.723-.543-1.401.814-2.08zM67.786 79.596a39.721 39.721 0 0111.805 0c1.13.18 2.035 1.04 2.262 2.125.859 4.161 1.085 8.277.678 12.439-.136 1.22-1.086 2.216-2.262 2.397a41.942 41.942 0 01-13.026 0c-1.221-.181-2.17-1.176-2.261-2.397-.407-4.162-.181-8.278.678-12.439.09-1.085.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M65.93 94.883a293.935 293.935 0 0115.47 0c1.356.679 1.628 1.402.813 2.08-5.698.137-11.397.137-17.096 0-.815-.723-.543-1.401.814-2.08zM47.456 79.596a39.721 39.721 0 0111.805 0c1.131.18 2.036 1.04 2.262 2.125.86 4.161 1.086 8.277.678 12.439-.135 1.22-1.085 2.216-2.261 2.397a41.942 41.942 0 01-13.026 0c-1.221-.181-2.171-1.176-2.262-2.397-.407-4.162-.18-8.278.679-12.439.09-1.085.995-1.945 2.125-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M45.601 94.883a293.935 293.935 0 0115.47 0c1.356.679 1.627 1.402.813 2.08-5.699.137-11.398.137-17.097 0-.814-.723-.542-1.401.814-2.08zM27.127 79.596a39.722 39.722 0 0111.805 0c1.131.18 2.036 1.04 2.262 2.125.86 4.161 1.085 8.277.678 12.439-.135 1.22-1.085 2.216-2.261 2.397a41.942 41.942 0 01-13.026 0c-1.222-.181-2.172-1.176-2.262-2.397-.407-4.162-.18-8.278.678-12.439.09-1.085.995-1.945 2.126-2.126z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
      <path
        d="M25.272 94.883a293.935 293.935 0 0115.469 0c1.357.679 1.628 1.402.814 2.08-5.699.137-11.398.137-17.097 0-.814-.723-.543-1.401.814-2.08zM102.587 8.013c0 2.85-13.117 7.01-29.31 7.01-16.192 0-29.308-4.16-29.308-7.01 0-2.85 13.116-5.201 29.309-5.201 16.192 0 29.309 2.307 29.309 5.201zM25.424 16.833V.55H1v16.283h24.424z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
      />
    </Svg>
  );
}

Flexible.propTypes = {};

Flexible.defaultProps = {};

export default Flexible;
